
import './App.scss';
import { useEffect, useReducer, useState } from 'react';
import Back from './helpers/Back';
import Cookies from 'js-cookie';
import ResponseComposer from './components/ResponseComposer';
import ThoughtGrid2 from './components/ThoughtGrid2';
import PostSessionScreen from './components/PostSessionScreen';
import AboutMeScreen from './components/AboutMeScreen';
import WelcomeScreen from './components/WelcomeScreen';
import UserContext from './UserContext';

function App() {

  const [selectedThought, setSelectedThought] = useState(null)
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [slam, setSlam] = useState(null);
  const [user, setUser] = useState(null);
  const [isSlamStarting, setIsSlamStarting] = useState(false);
  const [isSlamFinishing, setIsSlamFinishing] = useState(false);

  const [isSessionCardForceExpanded, setIsSessionCardForceExpanded] = useState(false);

  const [showAboutMeScreen, setShowAboutMeScreen] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [preQuestions, setPreQuestions] = useState(null);

  const [aboutSection, setAboutSection] = useState("Work");

  const [isNewUser, setIsNewUser] = useState(null);

  useEffect(
    () => {

      const isLaunched = (Date.now() / 1000 + 100000) > 1705069920;

      console.log('launch', isLaunched, Date.now() / 1000, 1705069920);

      if (!isLaunched) {
        return;
      }

      const searchParams = new URLSearchParams(window.location.search.slice(1));
      if (searchParams.get("gate")) {
        Cookies.set("auth-token", searchParams.get("gate"));
        window.location.replace(window.location.origin);
      }

      if (Back.isSignedIn()) {
        loadUser();
        setIsNewUser(false);
      } else {
        setIsNewUser(true);

        // Create a new user async here.
        loadTemporaryUser();
      }

    }, []
  );

  const handleGoToAbout = (section) => {
    setAboutSection(section);
    setShowAboutMeScreen(true);
  };

  const handleDoneAbout = () => {
    setShowAboutMeScreen(false);
  };

  const loadTemporaryUser = async () => {
    const response = await Back.createTemporaryUser();

    if (!response) {
      return;
    }

    response.user.isNew = true;
    response.user.popRound = 1;

    console.log('temp response', response, response.user, response.token);

    setUser(response.user);
    Cookies.set("auth-token", response.token);
    Back.refreshToken();
  };

  const handleThoughtSelected = async (thought) => {
    setSelectedThought(thought);
  };

  const handleWelcomeCompleted = () => {
    setShowWelcomeScreen(false);
  };

  // const handleAboutMeCompleted = async () => {

  //   setIsSlamStarting(true);

  //   const newSlam = await Back.startSlam(preQuestions);

  //   setTimeout(() => {
  //     setShowAboutMeScreen(false);
  //     setIsSlamStarting(false);
  //     setSlam(newSlam);
  //     setSelectedTarget("Procrastination");
  //   }, 2500);
  // };

  const onThoughtCompleted = async () => {

    if (slam.completed_thoughts.length === 3) {
      setIsSessionCardForceExpanded(true);
    } else {
      setIsSessionCardForceExpanded(true);
    }

  };

  const handleStartThoughtClicked = () => {
    setSelectedThought(null);
    setIsSessionCardForceExpanded(false);
  };
  const handleFinishSessionClicked = () => {
    setSelectedThought(null);
    setIsSessionCardForceExpanded(false);

    setIsSlamFinishing(true);
  };

  const handlePostEmotionsCompleted = () => {

  };


  const loadUser = async () => {
    const response = await Back.user();

    const user = response;

    user.isNew = false;
    user.popRound = 0;

    setUser(user);

    if (user.slam) {
      setSlam(user.slam);
    } else {

    }

    // window.Beacon('identity', {
    //   email: user.email
    // });
    // window.Beacon('prefill', {
    //   email: user.email
    // });

    // setIsSessionCardForceExpanded(true);
    // UI.toastWriting();
  }

  function changeUser(user) {
    setUser(user);
  }

  if (!user) {
    return (
      <div className="App"></div>
    );
  }

  return (
    <div className="App">
      <UserContext.Provider value={{ user, changeUser }}>
        <div className="Mast">
          <div className='logo'>
            <img src="air-logo.png" className='logo-img' />
          </div>
        </div>
        {(() => {
          if (isSlamFinishing) {
            return <PostSessionScreen slam={slam} onCompleted={handlePostEmotionsCompleted}></PostSessionScreen>
          }

          if (showWelcomeScreen) {
            return <WelcomeScreen onCompleted={handleWelcomeCompleted}></WelcomeScreen>
          }

          if (showAboutMeScreen) {
            return <AboutMeScreen category={aboutSection} onCompleted={handleDoneAbout}></AboutMeScreen>
          }

          // if (!slam) {
          //   return <PreSessionScreen onCompleted={handleEmotionsCompleted} showStartingMessage={isSlamStarting}></PreSessionScreen>
          // }

          if (!selectedThought && !isSlamFinishing) {
            return <ThoughtGrid2 slam={user.slam} user={user} changeUser={changeUser} target={selectedTarget} onThoughtSelected={handleThoughtSelected} onAboutClick={handleGoToAbout}></ThoughtGrid2>
          }


        })()}

        {/* <div className='level-bar-container'>
        <LevelBar></LevelBar>
      </div> */}

      </UserContext.Provider>
    </div >
  );
}

export default App;
