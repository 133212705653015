import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './AboutMeScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import EmotionQuestion from './EmotionQuestion';
import EmotionsGradeSheet from './EmotionsGradeSheet';
import EmotionsCompletedSheet from './EmotionsCompletedSheet';
import { ClipLoader } from 'react-spinners';
import AiMessage from './AiMessage';
import UserContext from '../UserContext';

function AboutMeScreen({ category, onCompleted }) {

    const { user, changeUser } = useContext(UserContext);

    const [isGraded, setIsGraded] = useState(false);


    const [showDoneButton, setShowDoneButton] = useState(false);

    const [showFinishingWorkMessage, setShowFinishingWorkMessage] = useState(false);
    const [showFinishingPersonalMessage, setShowFinishingPersonalMessage] = useState(false);
    const [showFinishingFinalMessage, setShowFinishingFinalMessage] = useState(false);


    const defaultAnswer = () => {
        if (category === "Work" && user.about_work_answer && user.about_work_answer.length > 1) {
            return user.about_work_answer;
        }
        if (category === "Personal" && user.about_personal_answer && user.about_personal_answer.length > 1) {
            return user.about_personal_answer;
        }
        return "";
    };
    const defaultHasStarted = () => {
        if (category === "Work" && user.about_work_answer && user.about_work_answer.length > 1) {
            return true;
        }
        if (category === "Personal" && user.about_personal_answer && user.about_personal_answer.length > 1) {
            return true;
        }
        return false;
    };
    const [answerText, setAnswerText] = useState(defaultAnswer());
    const [hasStartedAnswer, setHasStartedAnswer] = useState(defaultHasStarted());


    const handleGradeSheetCompleted = async (questions) => {




        //setShowStartingMessage(true);

        // setTimeout(() => {
        //     setShowStartingMessage(false);
        // }, 1500);

    };

    const handleChange = (e) => {
        console.log('value', e.target.value);

        let value = e.target.value;

        // TODO: TESTING ONLY.
        if (value === "sdf") {
            value = "I am a project manager at a startup. I started 6 months ago and I work remotely, which has made it hard to get to know my colleagues and get up to speed. I often find myself procrastinating during the workday.";
        }

        setAnswerText(value);
        setHasStartedAnswer(true);

        if (value.length > 36 && !showDoneButton) {
            setShowDoneButton(true);
        }
    };

    const handleBackClick = () => {
        onCompleted();
    };

    const handleDoneClick = async () => {

        document.querySelector('.sheet-content').style.opacity = "0.3";

        const response = await Back.saveAbout(answerText, category);

        console.log('saveAbout', response);

        if (category === "Work") {
            user.about_work_answer = answerText;
        }
        if (category === "Personal") {
            user.about_personal_answer = answerText;
        }

        changeUser({ ...user });

        setTimeout(() => {
            onCompleted();
        }, 1000);

    };

    setTimeout(() => {
        //setShowStartingMessage(true);
    }, 500);

    let className = "AboutMeScreen";

    if (isGraded) {
        className += " IsGraded";
    }
    if (showDoneButton) {
        className += " ShowDoneButton";
    }
    if (hasStartedAnswer) {
        className += " HasStartedAnswer";
    }

    // if (showStartingMessage) {
    //     className += ' ShowStartingMessage';
    // }

    const workSheet = (
        <div className='sheet WorkSheet'>
            <div className='sheet-content FadeInDelay FadeOut'>
                <div className='sheet-heading'>
                    {/* <img src="julia-face.png" className="julia-face-img" /> */}
                    <img src="work-about-head.png" className='work-about-head-img' />
                    <div className='label'>
                        <span className="life-title">My Work Life</span>
                    </div>

                </div>

                <div className='answer-fill'>
                    <textarea onChange={handleChange} className="about-answer" autoFocus={true} value={answerText} placeholder="I'm a project manager at a startup. I started 6 months ago and I work remotely, which has made it hard to get to know my colleagues and get up to speed. I often find myself procrastinating during the workday."></textarea>
                </div>

                {/* <div className='compose-icon-position'>
                    <img src="about-compose.png" className='compose-icon-img' />
                </div> */}

                <div className='work-scene-position'>
                    <img src="work-scene.svg" className='scene-svg' />
                </div>

                <div className='help-content'>
                    <div className='AiMention'>
                        <div className='julia-face'>
                            <img src="aiface-top.png" className='face-img face-top-img' />
                            <img src="aiface-bottom.png" className='face-img face-bottom-img' />
                        </div>
                        <div className='GreetingContent'>
                            {/* Since we're just meeting, what would you like me to know about you? */}
                            Is there anything you'd like to tell me about
                            your current work life?
                        </div>
                    </div>
                    <img src="about-arrow.png" className='ai-arrow-img' />
                    <div className='skip-instructions'>
                        &nbsp;
                    </div>
                </div>

                <div className='done-button-position'>
                    <div className='done-button skip-button' onClick={handleBackClick}>Back</div>
                </div>

                {(showDoneButton || hasStartedAnswer) && (
                    <div className='done-button-position'>
                        <div className='done-button' onClick={handleDoneClick}>Done!</div>
                    </div>
                )}

                <div className='questions-position'>
                    <div className='questions-sheet'>
                        <div className='heading-content'>
                            <div className='pen-area'>
                                <img src="about-notebook.png" className='pen-img' />
                            </div>
                            <div className='questions-heading examples-heading'>
                                Mention anything!
                                {/* Mention anything!<br /><span className="like-word">Like...</span> */}
                            </div>
                        </div>
                        <div className='questions-list'>
                            <div className='question'>
                                &bull; What you do
                            </div>
                            <div className='question'>
                                &bull; Your biggest goal right now
                            </div>
                            {/* <div className='question'>
                                        &bull; What are you goals over the next year or two?
                                    </div> */}
                            <div className='question'>
                                &bull; What you're struggling with
                            </div>

                            <div className='question'>
                                &nbsp; &nbsp;&nbsp;&bull; Anxiety? Procrastination?
                            </div>
                        </div>
                    </div>
                </div>

                <div className='examples-layout'>
                    <div className='questions-col'>

                    </div>
                    <div className='examples-col'>
                        <div className='examples-heading'>Example</div>
                        <div className='about-examples'>

                            <div className='example-name'>Sara</div>
                            <div className='example'>
                                I am a project manager at a startup.
                                I started 6 months ago and I work remotely,
                                which has made it hard to get to know my colleagues
                                and get up to speed. I often find myself procrastinating during the workday.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const personalSheet = (
        <div className='sheet WorkSheet PersonalSheetChanges'>
            <div className='sheet-content FadeInDelay FadeOut'>
                <div className='sheet-heading'>
                    {/* <img src="julia-face.png" className="julia-face-img" /> */}
                    <img src="work-about-head.png" className='work-about-head-img' />
                    <div className='label'>
                        <span className="life-title">My Personal Life</span>
                    </div>

                </div>

                <div className='answer-fill'>
                    <textarea onChange={handleChange} className="about-answer" autoFocus={true} value={answerText} placeholder="I'm 28 and am currently single but looking for a long-term relationship.
                            I moved to a new city last year and am still struggling to find new friends here.
                            I've been trying to go to the gym so I can improve my health and mood but have
                            struggled to go consistently."></textarea>
                </div>

                {/* <div className='compose-icon-position'>
                    <img src="about-compose.png" className='compose-icon-img' />
                </div> */}

                <div className='work-scene-position'>
                    <img src="personal-scene.svg" className='scene-svg' />
                </div>

                <div className='help-content'>
                    <div className='AiMention'>
                        <div className='julia-face'>
                            <img src="aiface-top.png" className='face-img face-top-img' />
                            <img src="aiface-bottom.png" className='face-img face-bottom-img' />
                        </div>
                        <div className='GreetingContent'>
                            {/* Since we're just meeting, what would you like me to know about you? */}
                            Is there anything you'd like to tell me about
                            your personal life right now?
                        </div>
                    </div>
                    <img src="about-arrow.png" className='ai-arrow-img' />
                    <div className='skip-instructions'>
                        &nbsp;
                    </div>
                </div>

                <div className='done-button-position'>
                    <div className='done-button skip-button' onClick={handleBackClick}>Back</div>
                </div>

                {showDoneButton && (
                    <div className='done-button-position'>
                        <div className='done-button' onClick={handleDoneClick}>Done!</div>
                    </div>
                )}

                <div className='questions-position'>
                    <div className='questions-sheet'>
                        <div className='heading-content'>
                            <div className='pen-area'>
                                <img src="about-notebook.png" className='pen-img' />
                            </div>
                            <div className='questions-heading examples-heading'>
                                Mention anything!
                                {/* Mention anything!<br /><span className="like-word">Like...</span> */}
                            </div>
                        </div>
                        <div className='questions-list'>
                            <div className='question'>
                                &bull; In a relationship?
                            </div>
                            <div className='question'>
                                &bull; Your social circle & goals
                            </div>
                            {/* <div className='question'>
                                        &bull; What are you goals over the next year or two?
                                    </div> */}
                            <div className='question'>
                                &bull; What you're struggling with
                            </div>

                            <div className='question'>
                                &nbsp; &nbsp;&nbsp;&bull; Maintaining relationships?
                            </div>
                            <div className='question'>
                                &nbsp; &nbsp;&nbsp;&bull; Building better habits?
                            </div>
                        </div>
                    </div>
                </div>

                <div className='examples-layout'>
                    <div className='questions-col'>

                    </div>
                    <div className='examples-col'>
                        <div className='examples-heading'>Example</div>
                        <div className='about-examples'>

                            <div className='example-name'>Sara</div>
                            <div className='example'>
                                I am a project manager at a startup.
                                I started 6 months ago and I work remotely,
                                which has made it hard to get to know my colleagues
                                and get up to speed. I often find myself procrastinating during the workday.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const oldPersonalSheet = (
        <div className='sheet PersonalSheet'>
            <div className='sheet-heading'>
                <img src="julia-face.png" className="julia-face-img" />
                <div className='label'>
                    Last thing! In your personal life, how are things going?
                </div>

            </div>

            <div className='answer-fill'>
                <textarea onChange={handleChange} className="about-answer" autoFocus={true} value={answerText}></textarea>
            </div>

            <div className='work-scene-position'>
                <img src="personal-scene.svg" className='personal-scene-svg' />
            </div>

            {showDoneButton && (
                <div className='done-button-position'>
                    <div className='done-button' onClick={handleDoneClick}>Done!</div>
                </div>
            )}

            <div className='examples-layout'>
                <div className='questions-col'>
                    <div className='questions-sheet'>
                        <div className='questions-heading examples-heading'>
                            Things you can mention...
                        </div>
                        <div className='questions-list'>
                            <div className='question'>
                                &bull; What's your occupation?
                            </div>
                            <div className='question'>
                                &bull; Are you generally happy with your work, or are you looking for a change?
                            </div>
                            {/* <div className='question'>
                                        &bull; What are you goals over the next year or two?
                                    </div> */}
                            <div className='question'>
                                &bull; Do you struggle with things like anxiety, procrastination,
                                or getting distracted?
                            </div>
                        </div>
                    </div>
                </div>
                <div className='examples-col'>
                    <div className='examples-heading'>Example</div>
                    <div className='about-examples'>

                        <div className='example-name'>Sara</div>
                        <div className='example'>
                            I'm 28 and am currently dating and looking for a long-term relationship.
                            I moved to a new city last year and am still struggling to find new friends here.
                            I've been trying to go to the gym so I can improve my health and mood but have
                            struggled to go consistently.
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={className}>

            <div className='sidebar'>
                <div className='logo'>
                    <img src="air-logo2.png" className='logo-img' />
                </div>


            </div>

            <div className='main-layout'>

                <div className='ai-col'>
                    {/* <div className='AiGreeting'>

                        <div className='steps-content'>
                            <div className='steps'><span className="step-phrase">Step 1</span> of 2</div>
                            <div className='step-title'>
                                My Work Life
                            </div>

                            <div className='step-instructions'>
                                <p>
                                    Write a couple of sentences about how your
                                    job<br />and work life are going.
                                </p>
                                <p>
                                    <b>This is optional.</b><br />It helps Air identify
                                    your thought patterns faster.
                                </p>
                            </div>
                        </div>



                    </div> */}
                </div>

                <div className='grade-col'>
                    {category === "Work" && workSheet}
                    {category === "Personal" && personalSheet}
                </div>

            </div>
        </div>
    );


}

function areRatingsCompleted(questions) {

    for (var q in questions) {
        console.log('are', questions[q].rating, questions[q]);
        if (!questions[q].rating) {
            return false;
        }
    }

    return true;
}

export default AboutMeScreen;
