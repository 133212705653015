import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './PostSessionScreen.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import EmotionQuestion from './EmotionQuestion';
import EmotionsGradeSheet from './EmotionsGradeSheet';
import EmotionsCompletedSheet from './EmotionsCompletedSheet';
import AiMessage from './AiMessage';
import SkillList from './SkillList';
import UserContext from '../UserContext';
import Confetti from 'react-dom-confetti';
import Select from 'react-select'

function PostSessionScreen({ slam }) {

    const [fireConfetti, setFireConfetti] = useState(false);
    const [fireSomewhatConfetti, setFireSomewhatConfetti] = useState(false);

    const { user, changeUser } = useContext(UserContext);

    const [hasAnswered, setHasAnswered] = useState(false);
    const [outcomeAnswer, setOutcomeAnswer] = useState(null);

    let className = "PostSessionScreen";

    const skills = [{ "label": "Self-esteem", "percentage": 2, "tier": "High" }, { "label": "Motivation", "percentage": 4, "tier": "High" }, { "label": "Focus", "percentage": 0, "tier": "High" }, { "label": "Purpose", "percentage": 0, "tier": "Mid" }, { "label": "Optimism", "percentage": 1, "tier": "Low" }, { "label": "Openness", "percentage": 0, "tier": "Mid" }];

    const answeredGreetings = {
        'Yes': "Great to hear! You earned it!",
        'Somewhat': "Good work! Each session can feel a little different.",
        'No': "Darn! Even though you don't feel better right now, you may notice an improved mood later in the day."
    }

    const dayOptions = [
        { value: 'Today', label: 'Today' },
        { value: 'Tomorrow', label: 'Tomorrow' },
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' }
    ];
    const timeOptions = [
        { value: 'Today', label: '9:00am' },
        { value: 'Tomorrow', label: '9:30am' },
        { value: 'Monday', label: '10:00am' },
        { value: 'Today', label: '11:00am' },
        { value: 'Tomorrow', label: '11:30am' },
        { value: 'Monday', label: '12:00pm' }
    ];

    const confettiConfigRight = {
        angle: 45,
        spread: 35,
        startVelocity: 52,
        elementCount: 36,
        dragFriction: 0.23,
        duration: 1600,
        stagger: 3,
        width: "8px",
        height: "8px",
        perspective: "600px",
        colors: ["#ff9f2e", "#5bfffd", "#cb4ce5"]
    };
    const confettiConfigLeft = {
        angle: 135,
        spread: 35,
        startVelocity: 52,
        elementCount: 36,
        dragFriction: 0.23,
        duration: 1600,
        stagger: 3,
        width: "8px",
        height: "8px",
        perspective: "600px",
        colors: ["#ff9f2e", "#5bfffd", "#cb4ce5"]
    };
    const confettiConfigSomewhat = {
        angle: 90,
        spread: 55,
        startVelocity: 48,
        elementCount: 38,
        dragFriction: 0.23,
        duration: 1600,
        stagger: 3,
        width: "7px",
        height: "7px",
        perspective: "600px",
        colors: ["#25aee6", "#25aee6", "#3fcc8f"]
    };

    const handleAnswer = async (answer) => {
        document.querySelector('.buttons-content').classList.add('Locked');
        const response = await Back.finishSlam(slam.id, answer);

        if (answer === "Yes") {
            setFireConfetti(true);
            document.querySelector('.big-button').classList.add('Firing');

            setTimeout(() => {
                document.querySelector('.big-button').classList.remove('Firing');
                document.querySelector('.big-button').classList.add('Shrink');
            }, 1000);
        }

        if (answer === "Somewhat") {
            setFireSomewhatConfetti(true);
            document.querySelector('.somewhat-button').classList.add('Firing');

            setTimeout(() => {
                document.querySelector('.somewhat-button').classList.remove('Firing');
                document.querySelector('.somewhat-button').classList.add('Shrink');
            }, 1000);
        }

        if (answer === "No") {
            document.querySelector('.no-button').classList.add('Firing');

            setTimeout(() => {
                document.querySelector('.no-button').classList.remove('Firing');
            }, 1000);

            setTimeout(() => {
                document.querySelector('.no-button').classList.add('Shrink');
            }, 1500);
        }

        setTimeout(() => {
            document.querySelector('.better-content').classList.add('Fade');
        }, 1200);

        setTimeout(() => {
            setHasAnswered(true);
            setOutcomeAnswer(answer);
        }, 2300);
    };

    const [email, setEmail] = useState("");
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handleSignInSubmitClick = async () => {
    }

    const handleRemindMeClick = () => {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            //alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            const notification = new Notification("Great! I'll remind you here when it's time for your session!");
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification("Great! I'll remind you here when it's time for your session!");
                    // …
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }

    let mainContent = (
        <div className='better-content'>

            <div className='heading-content'>
                <div className='heading-scale'>
                    <div className='subheading-better'>Session Completed!</div>

                    <div className='heading-row'>
                        <img src="session-completed.png" className='session-completed-img' />
                        <div className='heading-better'>
                            Do you feel better after your session?
                        </div>
                    </div>
                </div>
            </div>

            <div className='buttons-content'>
                <div className='big-button' onClick={() => handleAnswer('Yes')}>
                    <div className='button-label'>
                        <img src="outcome-yes.png" className='high-five-img' />
                        <div className='label-text'>Yes!</div>
                    </div>
                </div>
                <div className='small-buttons'>
                    <div className='small-button somewhat-button' onClick={() => handleAnswer('Somewhat')}>
                        <div className='button-label'>
                            <img src="outcome-somewhat.png" className='like-img' />
                            <div className='label-text'>A little!</div>
                        </div>
                    </div>
                    <div className='small-button no-button' onClick={() => handleAnswer('No')}>
                        <div className='button-label'>
                            <img src="outcome-no.png" className='like-img' />
                            <div className='label-text'>Not really</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='skills-content'>
                <div className='skills-heading'>
                    <img src="score.png" className='score-img' />
                    <div className='skills-heading-label'>
                        You improved some<br /><span className="skills-phrase">CBT Skills</span>!
                    </div>

                </div>
                <div className='skills-list-content'>
                    <SkillList skills={skills} isIncreaseSummary={true}></SkillList>
                </div>
            </div> */}

            <div className='confetti-positions'>
                <div className='confetti-yes-left'>
                    <Confetti active={fireConfetti} config={confettiConfigLeft} />
                </div>
                <div className='confetti-yes-right'>
                    <Confetti active={fireConfetti} config={confettiConfigRight} />
                </div>
                <div className='confetti-somewhat'>
                    <Confetti active={fireSomewhatConfetti} config={confettiConfigSomewhat} />
                </div>
            </div>

        </div>
    );

    if (hasAnswered) {
        mainContent = (
            <div className={`answered-content Answer${outcomeAnswer}`}>
                <div className='answered-heading'>
                    <div className='heading-label'>
                        <img src={`outcome-${outcomeAnswer.toLowerCase()}.png`} className='heading-icon' />
                        <div className='heading-label-text'>{answeredGreetings[outcomeAnswer]}</div>
                    </div>
                </div>

                <div className='answered-layout'>
                    <div className='return-col'>
                        <div className='return-content'>
                            <div className='faq-question'>
                                How to start your next session
                            </div>
                            <div className='return-instructions'>
                                When you're ready to start your<br />
                                <span className='next-session-phrase'>next session</span>,
                                just type
                            </div>
                            <div className='air-phrase'>
                                "air"
                            </div>
                            <div className='return-instructions'>
                                into your browser and click
                            </div>
                            <div className='url-phrase'>airtherapy.ai</div>
                            <div className='return-instructions'>
                                to start a session in <span className='em-phrase'>1 second</span>.
                            </div>
                        </div>
                    </div>
                    <div className='faq-col'>

                        <div className='RemindMe'>
                            <div className='remind-face'>
                                <div className='ai-face'>
                                    <img src="face-top-light.png" className='face-img face-top-img' />
                                    <img src="face-bottom-light.png" className='face-img face-bottom-img' />
                                </div>
                                <div className='remind-greeting'>
                                    When would you like to have<br />your next session?
                                </div>
                                <div className='recommend-instructions'>
                                    I recommend
                                </div>
                                <div className='time-selection'>
                                    <div className='day-control'>
                                        <Select options={dayOptions} styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "#fff",
                                                borderColor: '#ffe398',
                                                borderWidth: "2px"
                                            }),
                                        }} />
                                    </div>
                                    <div className='time-control'>
                                        <Select options={timeOptions} styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "#fff",
                                                borderColor: '#ffe398',
                                                borderWidth: "2px"
                                            }),
                                        }} />
                                    </div>
                                </div>
                                <div className='remind-button' onClick={handleRemindMeClick}>
                                    Remind Me
                                </div>
                                <div className='remind-button no-remind-button'>
                                    I'll return on my own
                                </div>
                                {/* <div className='recommend-instructions recommend-instructions-end'>
                                    ...but choose whatever works for you!<br />
                                    Or stop by whenever you have a minute, I'm always available!
                                </div> */}
                            </div>
                        </div>

                        <div className='faqs'>
                            <div className='faq-question'>
                                How often should I do sessions?
                            </div>
                            <div className='faq-answer'>
                                <p>
                                    It depends!
                                    For quick boosts in mood and motivation,
                                    feel free to do <span className='em-phrase'>multiple sessions</span> during the day as needed.
                                </p>
                                <p>
                                    For long-term benefits in mental health, focus, and mood,
                                    aim for<br />at least <span className='em-phrase'>6 sessions</span> per week.
                                </p>
                            </div>
                        </div>

                        <div className='GatherEmailCard'>
                            <div className='card-heading'>
                                Add your email to save your progress
                            </div>
                            {/* <div className='card-instructions'>
                                Adding an email allows us to save your progress.
                            </div> */}
                            <div className='card-form'>
                                <form onSubmit={handleSignInSubmitClick}>
                                    <input type="text" className="input-text" placeholder='Email' onChange={handleEmailChange} />
                                    <input type="submit" className="submit-button gtm-trial" value="Save" onClick={handleSignInSubmitClick} />
                                </form>
                            </div>
                            <div className='card-disclaimer'>
                                We will <u>never</u> share your email with third parties<br />
                                or send you marketing emails.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='AirPromise'>
                    <div className='heading-1'>Want real change?</div>
                    <div className='heading-2'>
                        <img src="contract.png" className='promise-img' />
                        Make Yourself A Promise
                    </div>
                    <div className='promise'>
                        <div className='sign-box'></div>
                        <div className='promise-clause'>
                            I promise myself that the next time I feel down,
                            I'll try a session with Air before engaging in unhealthy behaviors.
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={className}>
            <div className='sheet-heading'>
                &nbsp;
            </div>

            <div className='sheet'>
                {mainContent}
            </div>
        </div>
    );


}

export default PostSessionScreen;
