import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../helpers/Back';
import UserContext from '../UserContext';

import './AirIntro.scss';

function AirIntro({ }) {

    const { user, changeUser } = useContext(UserContext);
    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    const [messageClasses, setMessageClasses] = useState([]);

    function handleCloseClick() {
        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.AirIntro = null;
            changeUser({ ...user });
            setLockStyle(false);
        }, 100);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleCloseClick();
            }
        }

        const timeToShowTexts = 100;
        const timeToShowChat = 550;
        const timeAfterChat = 1200;

        let checkpoint = 1100;
        let delay1 = 500;

        setTimeout(() => {
            setMessageClasses((prev) => {
                prev.push("Message1");
                return [...prev];
            })
        }, checkpoint + 1000);

        setTimeout(() => {
            setMessageClasses((prev) => {
                prev.push("Message2");
                return [...prev];
            })
        }, checkpoint + 1000 + delay1 + timeToShowChat + timeAfterChat);

        setTimeout(() => {
            setMessageClasses((prev) => {
                prev.push("Message3");
                return [...prev];
            })
        }, checkpoint + 1000 + delay1 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);

        setTimeout(() => {
            setMessageClasses((prev) => {
                prev.push("Message4");
                return [...prev];
            })
        }, checkpoint + 1000 + delay1 + 1000 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);


        setTimeout(() => {
            setMessageClasses((prev) => {
                prev.push("FadeAll");
                return [...prev];
            })
        }, checkpoint + 1000 + delay1 + 3600 + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat + timeToShowChat + timeAfterChat);

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, user, changeUser]);

    let className = 'AirIntro drawer';

    // if (!user) {
    //     return (<div></div>);
    // }

    if (!lockStyle && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    //setOpacity(1);

    const parentStyles = {};
    //parentStyles.right = '0px';
    parentStyles.opacity = opacity;
    //parentStyles.left = opacity === "0.5" ? "0px" : "0px"

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    className += " " + messageClasses.join(" ");


    return (
        <div className={className} ref={ref} style={parentStyles}>

            <div className='ai-face-content'>
                <div className='ai-face'>
                    <img src="face-top-light.png" className='face-img face-top-img' />
                    <img src="face-bottom-light.png" className='face-img face-bottom-img' />
                </div>
            </div>

            <div className='air-messages'>
                <div className='air-message air-message-hi Message1'>Hi, I'm Air!</div>
                <div className='air-message Message2'>I'm a trained AI with one purpose &mdash;</div>
                <div className='air-message Message3'>to boost your energy and mood <br />in 3 minutes!</div>
                <div className='air-message air-message-end Message4'>So let's get into it!</div>
            </div>

        </div>
    )
};

export default AirIntro;
