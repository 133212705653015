import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import './SessionGrid.scss';
import ThoughtSetContent from './ThoughtSetContent';


function SessionGrid({ sessionThoughts, options, category, onSelection, isAnimatingCompletedThought, isPostSession }) {

    let className = "SessionGrid";

    className += " " + category;

    if (isPostSession) {
        className += " IsPostSession";
    }

    console.log('zzsessionThoughts', sessionThoughts);

    return (
        <div className={className} id="customThoughtContainer">

            <div className='thoughts'>

                {sessionThoughts.map(slot => {

                    let className = "SessionThought ";

                    if (slot.slide_in) {
                        className += " SlideIn";
                    }
                    if (slot.slide_in_number) {
                        className += " SlideInNumber";
                    }

                    if (slot.status === "Completed") {
                        className += " SessionThoughtCompleted";
                        return (
                            <div className={className}>

                                <ThoughtSetContent slot={slot} isGrid={true}></ThoughtSetContent>

                            </div>
                        );
                    }

                    if (slot.thought && !slot.is_hidden && !isAnimatingCompletedThought) {
                        className += slot.thought.type;
                        className += " SessionThoughtActive";

                        return (
                            <div className={className} key={slot.number}>
                                <div className='drop-space'>
                                    <div className='drop-card'>
                                        <div className='thought-content-active'>
                                            <div className='thought-label calc-lines-title'>
                                                {slot.thought.thought}
                                            </div>
                                            <div className='thought-label calc-lines-tester' id="fontSizeTester"></div>
                                            <div className='thought-checks'>
                                                {[1, 2, 3].map(check => {
                                                    let thoughtClass = 'thought-check';


                                                    return (
                                                        <div className={thoughtClass} key={check.number}>
                                                            <div className='checkmark-container'>
                                                                <img src="checkmark-blue.png" className='checkmark-img' />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }

                    className += " " + slot.status;

                    if (slot.is_hidden) {
                        className += " IsHidden";
                    }

                    return (
                        <div className={className}>
                            <div className='label'>{slot.number}</div>
                        </div>
                    );
                })}

            </div>

        </div>
    );


}

export default SessionGrid;