import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import User from '../helpers/User';
import LogThought from './LogThought';
import './ResponseLoadingGrid.scss';

import BeatLoader from "react-spinners/BeatLoader";
import ContentLoader from 'react-content-loader';

function ResponseLoadingGrid({ count }) {

    const items = [...Array(count).keys()];

    return (
        <div className="ResponseLoadingGrid">

            <div className='loading-grid'>
                <div className='loading-placeholders'>
                    {items.map(
                        p => {
                            return (
                                <div className='option'>
                                    <div className='option-content'>
                                        <ContentLoader viewBox="0 0 400 260"
                                            height={180} width={240}
                                            backgroundColor="#89daf5"
                                            foregroundColor="#0ea5e4"
                                        >
                                            <rect x="50" y="31" rx="4" ry="4" width="254" height="7" />
                                            <rect x="50" y="51" rx="3" ry="3" width="185" height="7" />



                                        </ContentLoader>
                                    </div>
                                </div>
                            )
                        }
                    )}

                </div>
            </div>

        </div>
    );


}

export default ResponseLoadingGrid;
