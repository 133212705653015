import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './ThoughtGrid2.scss';

import NegativeGrid from './NegativeGrid';
import ThoughtPreRating from './ThoughtPreRating';
import ResponseGrid from './ResponseGrid';

import { animated, useSpring, config, useTransition } from '@react-spring/web'
import SessionGrid from './SessionGrid';
import ThoughtPostRating from './ThoughtPostRating';
import PostSessionScreen from './PostSessionScreen';
import { CSSTransition } from 'react-transition-group';
import SkillList from './SkillList';
import CreateAccountDrawer from './CreateAccountDrawer';
import AnimationContext from '../AnimationContext';
import WizardContext from '../WizardContext';
import PageEffect from '../PageEffect';
import AirIntro from './AirIntro';
import StartMembershipScreen from './StartMembershipScreen';
import PickGoalsDrawer from './PickGoalsDrawer';
import AreaLog from './AreaLog';
import UI from './UI';

function ThoughtGrid2({ slam, user, changeUser, onAboutClick }) {

    const [viewName, setViewName] = useState("NegativeGrid");

    // RESPONSE LOADING
    const [options, setOptions] = useState([]);
    const [nextOptions, setNextOptions] = useState([]);
    const [previousOptions, setPreviousOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFakeLoading, setIsFakeLoading] = useState(false);
    const [isNextLoading, setIsNextLoading] = useState(false);
    const [isNextFresh, setIsNextFresh] = useState(false);
    // EO

    const [thought, setThought] = useState(null);

    const [showIntro, setShowIntro] = useState(false);

    // For CSSTransition of Main Content.
    const [inProp, setInProp] = useState(false);
    const nodeRef = useRef(null);

    const [completedSlotMessage, setCompletedSlotMessage] = useState(null);
    const [showCompletedSlotMessage, setShowCompletedSlotMessage] = useState(false);

    const [skills, setSkills] = useState(user.skills);
    const [skillIncreaseName, setSkillIncreaseName] = useState(null);
    const [skillIncreaseAmount, setSkillIncreaseAmount] = useState(null);

    const [areaIncreases, setAreaIncreases] = useState({
        "Mood": 0, "Motivation": 0, "Focus": 0, "Ease": 0, "Purpose": 0, "Openness": 0
    });

    const [pageEffect, setPageEffect] = useState("");
    const [pageEffects, setPageEffects] = useState([]);
    const pageEffectsRef = useRef([]);

    const isNextFreshRef = useRef();
    const nextOptionsRef = useRef();
    const previousOptionsRef = useRef([]);

    const [currentDate, setCurrentDate] = useState(new Date);

    useEffect(() => {
        setTimeout(() => {
            setInProp(true);
        }, 100);

        if (user.isNew && user.popRound === 1) {
            setShowIntro(true);
            setTimeout(() => {
                setShowIntro(false);
            }, 13500);
        }
    }, []);

    const [sessionThoughts, setSessionThoughts] = useState(slam.slots);
    const [preRating, setPreRating] = useState(null);

    const [responses, setResponses] = useState([]);
    const [strikingLineNumber, setStrikingLineNumber] = useState(0);
    const [strikeWidths, setStrikeWidths] = useState([0, 0, 0]);

    const [showResponsesDoneButton, setShowResponsesDoneButton] = useState(false);

    const [isThoughtSingleLine, setIsThoughtSingleLine] = useState(null);

    const [isAnimatingCompletedThought, setIsAnimatingCompletedThought] = useState(false);

    const isReframingType = false;

    const [debugs, setDebugs] = useState(["First"]);

    const animations = {
        addPageEffect: (e) => {
            const oldPageEffects = [...pageEffectsRef.current];
            const newPageEffects = [...pageEffectsRef.current, e];

            setPageEffects(newPageEffects);
            pageEffectsRef.current = newPageEffects;

            console.log('PPG addPageEffect', e, pageEffectsRef.current, newPageEffects, oldPageEffects);
        },

        removePageEffect: (e) => {
            const oldPageEffects = [...pageEffectsRef.current];
            const newPageEffects = pageEffectsRef.current.filter(i => i !== e);

            setPageEffects(newPageEffects);
            pageEffectsRef.current = newPageEffects;

            console.log('PPG removePageEffect', e, pageEffectsRef.current, newPageEffects, oldPageEffects);
        },
        de: (e) => {
            const newDebugs = [e];

            setDebugs(newDebugs);
        }
    };

    const wizard = {
        pop: (name, domQuery, shift, positions, onClose) => {

        }
    };

    console.log('user', user);

    const handleThoughtClick = async (thought) => {

        const pendingSlot = sessionThoughts.find(t => t.status === "Pending");
        const pendingSlotIndex = sessionThoughts.findIndex(t => t.status === "Pending");

        const newSlot = { ...pendingSlot };
        newSlot.status = "Active";
        newSlot.thought = thought;

        if (thought.type === "Reinforce") {
            newSlot.reps = thought.thought_set.reps;
            newSlot.is_hidden = true;
        } else {
            newSlot.reps = [
                { status: "Pending" },
                { status: "Pending" },
                { status: "Pending" }
            ];
        }

        const newSessionThoughts = [...sessionThoughts];
        newSessionThoughts[pendingSlotIndex] = newSlot;

        setSessionThoughts(newSessionThoughts);
        setThought(newSlot.thought);

        if (thought.type === "Reinforce") {

            //return;

            loadOptions(newSlot.thought);

            setPageEffect("FadeOutSessionGrid HideExpandedBackground");

            setTimeout(() => {
                console.log('VV3 setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");');
                setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");
            }, 100);

            // Start Fading In Expanded Evidence Background (Tan)
            setTimeout(() => {
                console.log('VV2 setViewName("ResponseGrid");');
                setViewName("ResponseGrid");
            }, 200);



            // Slide Session Card Up
            // setTimeout(() => {
            //     expandSessionCard(null, true);
            // }, 600);

            // setTimeout(() => {
            //     setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");
            // }, 1200);

            // Switch To New View "ResponseGrid" Without Visual Tears
            setTimeout(() => {
                console.log('VV4 setPageEffect("");');
                setPageEffect("");
            }, 850);



            // setTimeout(() => {
            //     setViewName("ResponseGrid");
            // }, 1250);

            return;
        }

        setViewName("ThoughtPreRating");
        //setViewName("ResponseGrid");

        setTimeout(() => {
            calcLines();
            const isSingleLine = document.querySelector('.SessionThoughtActive .calc-lines-title').getBoundingClientRect().height < 45;
            setIsThoughtSingleLine(isSingleLine);
        }, 200);


        loadOptions(newSlot.thought);

    };

    const handlePostSessionCompleted = (questions) => {
    };

    const handlePreRating = (rating) => {
        setPreRating(rating);

        const newEl = copySessionCardToExpand();

        setTimeout(() => {
            setPageEffect("FadeOutSessionGrid HideExpandedBackground");
        }, 100);

        setTimeout(() => {
            setViewName("ResponseGrid");
        }, 600);

        setTimeout(() => {
            expandSessionCard(newEl);
        }, 600 - 250);

        setTimeout(() => {
            setPageEffect("FadeOutSessionGrid HideExpandedBackground FadeInExpandedBackground");
        }, 1200 - 100);

        setTimeout(() => {
            setPageEffect("");
        }, 1900);

    };

    const handleThoughtCompleted = (completedSlot, skillIncreases, updatedUser) => {

        const pendingSlot = sessionThoughts.find(t => t.status === "Active");
        const pendingSlotIndex = sessionThoughts.findIndex(t => t.status === "Active");

        console.log('updatedUser', updatedUser, user);
        user.areas = updatedUser.areas;
        changeUser({ ...user });

        // Clean up slides.
        let previousSlot = sessionThoughts.find(t => t.slide_in === true);
        let previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in === true);
        if (previousSlot) {
            previousSlot.slide_in = false;
            sessionThoughts[previousSlotIndex] = { ...previousSlot };
        }
        previousSlot = sessionThoughts.find(t => t.slide_in_number === true);
        previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in_number === true);
        if (previousSlot) {
            previousSlot.slide_in_number = false;
            sessionThoughts[previousSlotIndex] = { ...previousSlot };
        }
        previousSlot = sessionThoughts.find(t => t.slide_in === true);
        previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in === true);
        if (previousSlot) {
            previousSlot.slide_in = false;
            sessionThoughts[previousSlotIndex] = { ...previousSlot };
        }
        previousSlot = sessionThoughts.find(t => t.slide_in_number === true);
        previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in_number === true);
        if (previousSlot) {
            previousSlot.slide_in_number = false;
            sessionThoughts[previousSlotIndex] = { ...previousSlot };
        }
        previousSlot = sessionThoughts.find(t => t.slide_in === true);
        previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in === true);
        if (previousSlot) {
            previousSlot.slide_in = false;
            sessionThoughts[previousSlotIndex] = { ...previousSlot };
        }
        previousSlot = sessionThoughts.find(t => t.slide_in_number === true);
        previousSlotIndex = sessionThoughts.findIndex(t => t.slide_in_number === true);
        if (previousSlot) {
            previousSlot.slide_in_number = false;
            sessionThoughts[previousSlotIndex] = { ...previousSlot };
        }

        const newSessionThoughts = [...sessionThoughts];
        newSessionThoughts[pendingSlotIndex] = { ...completedSlot };

        const isReinforce = completedSlot.thought.type === "Reinforce";

        setSessionThoughts(newSessionThoughts);

        setViewName("NegativeGrid");

        animations.addPageEffect("HoldNegativeGrid");
        animations.addPageEffect("PrepPostSessionEnter");

        const reinforceDelayAdd = isReinforce ? 1400 : 0;

        const isSessionCompleted = !sessionThoughts.find(t => t.status === "Pending");

        setTimeout(() => {

            if (isSessionCompleted) {
                //setPageEffects(["SlideOutSessionGrid", "HoldNegativeGrid"]);
                animations.addPageEffect("SlideOutSessionGrid");
                animations.addPageEffect("HoldNegativeGrid");

                const thisDelay = 1200;
                setTimeout(() => {
                    animations.addPageEffect("PrepPostSessionEnter");
                    animations.addPageEffect("HoldNegativeGrid");
                    animations.addPageEffect("SlideOutSessionGrid");
                    animations.addPageEffect("PrepPostSessionEnter");
                    setViewName("PostSession");
                }, thisDelay + 50 + 0);
                setTimeout(() => {
                    animations.addPageEffect("HoldNegativeGrid"); // Redundant probably but it's being weird.
                    animations.removePageEffect("SlideOutSessionGrid");
                    animations.addPageEffect("PrepPostSessionEnter");

                }, thisDelay + 600 + 0);

                setTimeout(() => {
                    animations.removePageEffect("PrepPostSessionEnter");
                    animations.removePageEffect("HoldNegativeGrid");
                }, thisDelay + 700 + 0);

            }
        }, 2000 + reinforceDelayAdd);

        setIsAnimatingCompletedThought(false);

        setOptions([]);
        setNextOptions([]);
        nextOptionsRef.current = [];
        setIsLoading(true);
        setThought(null);
        setResponses([]);
        setTransitionItems([]);
        setStrikingLineNumber(0);
        setStrikeWidths([0, 0, 0]);
        setAreaIncreases({ "Mood": 0, "Motivation": 0, "Focus": 0, "Ease": 0, "Purpose": 0, "Openness": 0 });

        if (user.isNew && user.popRound === 1) {
            user.popRound = 2;
            changeUser({ ...user });
        }
        else if (user.isNew && user.popRound === 2) {
            user.popRound = 3;
            changeUser({ ...user });
        }

        setCompletedSlotMessage({
            number: completedSlot.number,
            video: null,
            label: getRotatingCheckLabel(completedSlot.number)
        });

        const completedMessageDelay = 650 + reinforceDelayAdd;
        setTimeout(() => {
            setShowCompletedSlotMessage(true);
        }, completedMessageDelay);
        setTimeout(() => {
            setShowCompletedSlotMessage(false);
            if (!isSessionCompleted) {
                animations.removePageEffect("HoldNegativeGrid");
            }
        }, completedMessageDelay + 1500);
        setTimeout(() => {
            setCompletedSlotMessage(null);
        }, completedMessageDelay + 2500);

        if (!isSessionCompleted) {
            setTimeout(() => {
                setPageEffects([]);
                animations.removePageEffect("PrepPostSessionEnter");
            }, completedMessageDelay + 3600);
        }

        setTimeout(() => {
            const slideEl = document.querySelector('.SlideIn');
            if (slideEl) {
                slideEl.classList.remove("SlideIn");
            }
        }, 700);
        setTimeout(() => {
            const slideEl = document.querySelector('.SlideInNumber');
            if (slideEl) {
                slideEl.classList.remove("SlideInNumber");
            }
        }, isReinforce ? 1400 : 100);

        // Backup retry for race conditions?
        setTimeout(() => {
            const slideEl = document.querySelector('.SlideIn');
            if (slideEl) {
                slideEl.classList.remove("SlideIn");
            }
        }, 1000);
        setTimeout(() => {
            const slideEl = document.querySelector('.SlideInNumber');
            if (slideEl) {
                slideEl.classList.remove("SlideInNumber");
            }
        }, isReinforce ? 1900 : 500);


        // TODO: Disabled temp.
        return;
        let delay = 10;
        for (let i in skillIncreases) {
            const skillIncrease = skillIncreases[i];

            console.log('itz skill increase', skillIncrease);

            if (skillIncrease.increase < 1) {
                continue;
            }

            setTimeout(() => {
                setSkillIncreaseName(skillIncrease.name);
                setSkillIncreaseAmount(skillIncrease.increase);
            }, delay + 10)

            setTimeout(() => {
                const newSkills = [...skills];
                const thisSkill = newSkills.find(i => i.label === skillIncrease.name);
                const thisSkillIndex = newSkills.findIndex(i => i.label === skillIncrease.name);

                thisSkill.percentage = skillIncrease.new;

                newSkills[thisSkillIndex] = thisSkill;

                setSkills(newSkills);

            }, delay + 1900);

            delay += 500;
        }

    }

    const copySessionCardToExpand = () => {
        const el = document.querySelector('.SessionThoughtActive');
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 5.0 + 10 + 'px';
        newEl.style.left = destinationRect.left + 0 + 14 + 6 + 'px';
        newEl.style.width = "360px";

        newEl.style.boxSizing = "border-box";
        newEl.querySelector('.drop-card').style.boxShadow = "none";
        newEl.style.background = "transparent";
        newEl.style.backgroundColor = "transparent";

        newEl.style.transition = ".4s";

        document.querySelector('.ThoughtGrid2').appendChild(newEl);

        return newEl;
    };

    const expandSessionCard = (newEl, isReinforce) => {
        const el = document.querySelector('.SessionThoughtActive');
        const destEl = document.querySelector('.drop-dest');

        if (!newEl || isReinforce) {
            return;
        }

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        setTimeout(() => {
            newEl.style.transform = "scale(1.1)";
            newEl.querySelector('.drop-card').style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        }, 10);

        setTimeout(() => {
            newEl.style.top = destinationRect.top - 25 + 'px';
            newEl.style.left = destinationRect.left + 0 + 'px';
            newEl.style.width = "373px";
            newEl.style.boxSizing = "border-box";
            //newEl.querySelector('.drop-card').style.boxShadow = null;


        }, 300);

        setTimeout(() => {

            newEl.style.transform = "scale(1.0)";
            newEl.querySelector('.drop-card').style.boxShadow = null;
        }, 875);

        setTimeout(() => {
            newEl.remove();
        }, 1150);
    };

    const handleCreateAccountClick = () => {
        user.CreateAccountDrawer = true;
        changeUser({ ...user });
    };

    const handleResponseAdded = (option, isStrongPress) => {

        const strikesExpected = 6;
        if (thought.type !== "Reinforce") {
            console.log('dds', strikesExpected);
            setTimeout(() => {
                drawStrikeSegment(strikesExpected);
            }, 100);
        }

        responses.push(1);
        setResponses(responses);

        // Here we load and get the video message in position to transition in
        // once the user finishes their Evidence Sheet.
        if (responses.length === 1) {
            const rotatingContentNumber = getRotatingCompletionNumber(user.completed_thoughts_count + 1);
            setCompletedSlotMessage({
                number: 4,
                video: getRotatingCompletionVideo(rotatingContentNumber),
                label: getRotatingCompletionLabel(rotatingContentNumber)
            });
        }

        if (responses.length >= strikesExpected) {
            //setShowResponsesDoneButton(true);

            user.completed_thoughts_count++;
            changeUser({ ...user });



            setTimeout(() => {
                var videoEl = document.querySelector('.completed-video');
                if (videoEl) {
                    console.log('video currentTime set');
                    videoEl.currentTime = 0.9;
                }
                setShowCompletedSlotMessage(true);
            }, 50);

            setTimeout(() => {
                animations.addPageEffect("HideResponseGrid");
            }, 300);

            setTimeout(() => {
                setViewName("ThoughtPostRating");
            }, 800);

            setTimeout(() => {
                setShowCompletedSlotMessage(false);
            }, 2100);

            setTimeout(() => {
                animations.removePageEffect("HideResponseGrid"); // Clean up.
            }, 3400);
        }

        let areaName = UI.extractWordInBrackets(option);
        let sentence = UI.removeBracketedWord(option);

        const areaOptions = ["Mood", "Motivation", "Focus", "Ease", "Purpose", "Openness"];
        if (areaName === "Possibility") {
            areaName = "Openness";
        }
        if (!areaOptions.includes(areaName)) {
            areaName = "Ease";
        }

        const newAreaIncreases = { ...areaIncreases };
        newAreaIncreases[areaName] += 1;
        setAreaIncreases(newAreaIncreases);

        //thought.areaIncreases = newAreaIncreases;
        console.log('zz newArea', newAreaIncreases);

        const transitionItem = {
            option: option,
            sentence: sentence,
            area: areaName,
            isStrongPress: isStrongPress,
            number: responses.length
        };

        setTransitionItems(
            (prev) => {
                return [...prev, transitionItem];
            }
        );

    };

    const drawStrikeSegment = (strikesExpected) => {

        // Fancy JS to calculate the pixel length of each line of our Negative thought.
        // Unoptimized to call it everytime here though.
        const lineWidths = calcLines();

        // Messy way to initialize our strike widths array 
        // to have one 0 value for each line we know we WILL be striking in the future.
        // Really, this should be happening elsewhere.
        let initStrikeWidths = [];
        if (!strikeWidths) {
            for (var i = 0; i < lineWidths.length; i++) {
                initStrikeWidths.push(0);
            }
        } else {
            initStrikeWidths = strikeWidths;
        }

        const totalWidth = lineWidths.reduce((s, a) => s + a, 0);
        const segWidth = totalWidth / strikesExpected;

        const currentLineWidth = lineWidths[strikingLineNumber];
        const currentStrikeWidth = initStrikeWidths[strikingLineNumber];

        let newStrikeWidth = currentStrikeWidth + segWidth;
        let remainingStrikeWidth = 0;

        // Check if there will be REMAINING strike to consider.
        // Meaning, any time we reach the end of the line, we'll be striking on TWO LINES -- 
        // the current line to finish striking it out, and then a little bit on the next line too.
        if (newStrikeWidth > currentLineWidth) {
            remainingStrikeWidth = newStrikeWidth - currentLineWidth;
            newStrikeWidth = currentStrikeWidth;
        }

        const newStrikeWidths = [...initStrikeWidths];

        // If there was any remaining strike, that means 
        // we're FINISHING our strike for the current line we were striking,
        // and then adding the FIRST BIT of strike for the next line we've moving onto.
        if (remainingStrikeWidth) {
            newStrikeWidths[strikingLineNumber] = currentLineWidth;
            newStrikeWidths[strikingLineNumber + 1] = remainingStrikeWidth;
        } else {
            newStrikeWidths[strikingLineNumber] = newStrikeWidth;
        }

        console.log('dds setStrikeWidths', newStrikeWidths);

        // Save our new strike widths for each line.
        // This will update the UI strike lines for us.
        setStrikeWidths(newStrikeWidths);

        // If we had a remaining strike at all, 
        // that means we've moved to the next line to strike for next time.
        if (remainingStrikeWidth) {
            setStrikingLineNumber(strikingLineNumber + 1);
        }
    };

    const handleResponseRefreshRequested = (isRetry) => {
        setIsLoading(true);
        console.log('bb handleRefreshRequested', isNextFreshRef.current, isNextFresh, isNextLoading, isLoading, isNextFreshRef);



        if (isNextFreshRef.current === true) {

            setTimeout(() => {
                console.log('bb ISNEXTFRESH', nextOptions, nextOptionsRef.current);
                setIsFakeLoading(true);
                setOptions(nextOptionsRef.current);
                setIsLoading(false);
                setIsFakeLoading(false);
                setIsNextFresh(false);
                isNextFreshRef.current = false;
                loadNextOptions(thought);

                [].forEach.call(document.querySelector('.ResponseGrid').querySelectorAll('.Selected'), function (el) {
                    el.classList.remove('Selected');
                });
                console.log('bb ISNEXTFRESH DONE');

            }, isRetry ? 400 : 400);
            return;
        }

        if (isNextLoading) {
            console.log('bb running again');
            setTimeout(
                () => {
                    handleResponseRefreshRequested(true);
                }, 200
            )
            return;
        }

        console.log('bb NO MANS LAND');
    };

    const handleResponsesDoneClick = () => {

    };

    const areasLog = [
        { name: "Motivation", isFocused: true, score: 3.9, progress: 60 },
        { name: "Focus", isFocused: true, score: 3.1, progress: 24 },
        { name: "Mood", isFocused: false, score: null, progress: 52 },
        { name: "Ease", isFocused: false, score: null, progress: 12 },
        { name: "Purpose", isFocused: false, score: 2.7, progress: 22 },
        { name: "Openness", isFocused: false, score: 1.9, progress: 82 }
    ];
    // const areasLog = [
    //     { name: "Mood", isFocused: true, score: 3.9, progress: 60 },
    //     { name: "Ease", isFocused: true, score: 3.1, progress: 24 },
    //     { name: "Focus", isFocused: false, score: null, progress: 12 },
    //     { name: "Motivation", isFocused: false, score: null, progress: 52 },
    //     { name: "Purpose", isFocused: false, score: 2.7, progress: 22 },
    //     { name: "Openness", isFocused: false, score: 1.9, progress: 82 }
    // ];

    // TEST CASES TESTING
    useEffect(() => {

        setTimeout(() => {

            // setShowCompletedSlotMessage(false);
            // setCompletedSlotMessage({
            //     number: 3,
            //     video: null,
            //     label: getRotatingCheckLabel(3)
            // });

            const rotatingContentNumber = getRotatingCompletionNumber(user.completed_thoughts_count);
            // setCompletedSlotMessage({
            //     number: 4,
            //     video: getRotatingCompletionVideo(rotatingContentNumber),
            //     label: getRotatingCompletionLabel(rotatingContentNumber)
            // });

            // setShowCompletedSlotMessage(true);
        }, 500);

        // setTimeout(() => {
        //     setShowCompletedSlotMessage(true);
        // }, 950);

        // setTimeout(() => {
        //     setShowCompletedSlotMessage(false);
        // }, 3950);


        // setViewName("ThoughtPostRating");
        // setThought({
        //     type: "Reinforce",
        //     thought: "I'm not smart enough to be an entrepreneur",
        //     slam_id: 1,
        //     reframing_thought: "I can try my best to be an entrepreneur",
        //     pre_rating: 80,
        //     rating: 50,
        //     reframing_rating: 60,
        //     category: "Work"
        // });

        // const t = { "thought": "I'll never finish on time", "type": "Negative", "ratings": [], "reframing_ratings": [], "category": "Work" };
        // setSessionThoughts(
        //     [{ "id": 1, "number": 1, "status": "Active", "thought": { "thought": "I'll never finish on time", "type": "Negative", "ratings": [], "reframing_ratings": [], "category": "Work" }, "reps": [{ "status": "Pending" }, { "status": "Pending" }, { "status": "Pending" }] }, { "id": 2, "number": 2, "status": "Pending" }, { "id": 3, "number": 3, "status": "Pending" }]
        // );
        // setThought(
        //     t
        // );
        // setViewName("ResponseGrid");

        // loadOptions(t);

        // setViewName("ThoughtPostRating");
        // setThought({
        //     type: "Negative",
        //     thought: "I'm not smart enough to be an entrepreneur",
        //     slam_id: 1,
        //     reframing_thought: "I can try my best to be an entrepreneur",
        //     pre_rating: 80,
        //     rating: 50,
        //     reframing_rating: 60,
        //     category: "Work"
        // });

    }, []);

    const onAnimatingCompletedThought = (slot) => {

        if (slot && slot.thought.type === "Reinforce") {
            setViewName("NegativeGrid");
            //setPageEffects(["FadeOutSessionGrid"]);
            animations.addPageEffect("FadeOutSessionGrid");
            setTimeout(() => {
                //setPageEffects(["FadeInSessionGrid"]);
            }, 1000);
            return;
        }

        setIsAnimatingCompletedThought(true);

        setTimeout(() => {
            document.querySelector('.AnimatedFloat').classList.add('AnimatedFloatFade');
        }, 100);
    };

    let mainContent = null;

    if (viewName === "NegativeGrid") {
        mainContent = <NegativeGrid sessionThoughts={sessionThoughts} onThoughtSelected={handleThoughtClick}></NegativeGrid>
    }

    if (viewName === "ThoughtPreRating") {
        mainContent = <ThoughtPreRating thought={thought} onCompleted={handlePreRating} sessionThoughts={sessionThoughts}></ThoughtPreRating>
    }

    if (viewName === "ResponseGrid") {
        mainContent = <ResponseGrid thought={thought} options={options} nextOptions={nextOptions} isLoading={isLoading} isNextLoading={isNextLoading} isNextFresh={isNextFresh} onResponseAdded={handleResponseAdded} onRefreshRequested={handleResponseRefreshRequested}></ResponseGrid>
    }

    if (viewName === "ThoughtPostRating") {
        mainContent = <ThoughtPostRating thought={thought} slam={slam} onCompleted={handleThoughtCompleted} sessionThoughts={sessionThoughts} preRating={preRating} areaIncreases={areaIncreases} onAnimatingCompletedThought={onAnimatingCompletedThought}></ThoughtPostRating>
    }

    if (viewName === "PostSession") {
        mainContent = <PostSessionScreen slam={slam} sessionThoughts={sessionThoughts} onCompleted={handlePostSessionCompleted}></PostSessionScreen>
    }

    if (viewName === "StartMembership") {
        mainContent = <StartMembershipScreen slam={slam} sessionThoughts={sessionThoughts} onCompleted={handlePostSessionCompleted}></StartMembershipScreen>
    }

    const [show, set] = useState(false);
    const [transitionItems, setTransitionItems] = useState([]);
    const transitions = useTransition(transitionItems, {
        from: { opacity: 0, x: 40 },
        enter: { opacity: 1, x: 0 },
        leave: { opacity: 0, x: 12 },
        reverse: show,
        delay: 0,
        config: config.gentle,
        //onRest: () => setTransitionItems([])
    });

    let className = "ThoughtGrid2";

    className += " " + viewName + "Page";

    className += " " + pageEffect;

    if (pageEffectsRef.current && pageEffectsRef.current.length) {
        className += pageEffectsRef.current.join(" ");
    }

    if (isAnimatingCompletedThought) {
        className += " " + "IsAnimatingCompletedThought";
    }

    // RESPONSE LOADING


    const loadOptions = async (loadThought) => {
        var group = 0;
        var attempts = 0;
        while (true) {
            attempts = attempts + 1;

            if (attempts > 5) {
                //setOptions([]);
                break;
            }

            //const requestedCount = (group === 1 || group === 2) ? 4 : 2;
            let response = null;
            if (loadThought.type === "Reinforce") {
                response = await Back.respondToReinforcementThought(loadThought.reframing_thought, options, group, 8, isReframingType);
            } else {
                response = await Back.respondToThought(loadThought.thought, options, group, 8, isReframingType);
            }

            if (!response) {
                console.log('loadOptions', 'bad response');
                continue;
            }

            const items = response.items;

            if (items && Array.isArray(items) && items.length > 0) {

                for (var i in items) {
                    if (typeof items[i] !== "string") {
                        console.log('Non string item', items[i]);
                        continue;
                    }
                }

                var highlightIndex = Math.floor(Math.random() * items.length);
                var highlightItem = items[highlightIndex];

                //setHighlightedOption(highlightItem);

                console.log('loadOptions', 'setOptions');

                setOptions(prev => [...prev, ...items]);
                break;
            } else {
                console.log('loadOptions Bad response', response, items);
            }
        }

        setIsLoading(false);

        loadNextOptions(loadThought);
    };

    const loadNextOptions = async (loadThought) => {
        setIsNextLoading(true);
        var attempts = 0;
        while (true) {
            console.log('bb loadNextOptions WHILE');
            attempts = attempts + 1;

            if (attempts > 5) {
                setNextOptions([]);
                break;
            }

            let response = null;
            if (loadThought.type === "Reinforce") {
                response = await Back.respondToReinforcementThought(loadThought.reframing_thought, previousOptions, 0, 8, isReframingType);
            } else {
                console.log('previousOptions', previousOptionsRef.current);
                response = await Back.respondToThought(loadThought.thought, previousOptionsRef.current, 0, 8, isReframingType);
            }

            if (!response) {
                continue;
            }

            const items = response.items;

            if (items && Array.isArray(items) && items.length > 0) {
                previousOptionsRef.current = [...previousOptionsRef.current, ...items];
                setNextOptions(items);
                nextOptionsRef.current = items;
                break;
            }
        }

        console.log('bb loadNextOptions DONE END');
        setIsNextLoading(false);
        setIsNextFresh(true);
        isNextFreshRef.current = true;
    };
    // EO RESPONSE LOADING


    let doneButtonClass = "d-button Show";

    if (showResponsesDoneButton) {
        doneButtonClass += " Show";
    }
    if (!showResponsesDoneButton) {
        doneButtonClass += " Hidden";
    }
    if (viewName === "ThoughtPostRating") {
        doneButtonClass += " Leave";
    }



    return (
        <div className={className}>
            <AnimationContext.Provider value={animations}>
                <WizardContext.Provider value={wizard}>

                    {/* <div className='main-heading'>
                <div className='heading-prompt'>
                    What's on your mind?
                </div>
            </div> */}


                    {showIntro === true && (
                        <div>
                            <AirIntro></AirIntro>
                            <div className='AirIntroOverlay'></div>
                        </div>
                    )}


                    <CreateAccountDrawer></CreateAccountDrawer>
                    {/* <PickGoalsDrawer></PickGoalsDrawer> */}

                    <div className='sidebar'>
                        <div className='logo'>
                            <img src="air-logo2.png" className='logo-img' />
                        </div>

                        <div className='YourTherapy'>

                            <div className='sidebar-subheading'>Your therapy</div>

                            <AreaLog areas={areasLog}></AreaLog>

                            {/* <div className='stage-head'>
                                <div className='icon'><img src={`skill-self-esteem.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>Mood</div>
                                <div className='progress-number'>3.9 <span className="outof-phrase">/ 5</span></div>
                            </div>
                            <div className='stage-progress'>
                                <div className='stage-progressbar'>
                                    <div className='stage-fillbar'></div>
                                </div>
                            </div>

                            <div className='stage-head'>
                                <div className='icon'><img src={`skill-focus.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>Focus</div>
                                <div className='progress-number'>2.5 <span className="outof-phrase">/ 5</span></div>
                            </div>
                            <div className='stage-progress effort-progress'>
                                <div className='stage-progressbar'>
                                    <div className='stage-fillbar'></div>
                                </div>
                            </div>

                            <div className='stage-head waiting-head waiting-head-first'>
                                <div className='icon'><img src={`skill-motivation.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>Motivation</div>
                                <div className='progress-number'></div>
                            </div>

                            <div className='stage-head waiting-head'>
                                <div className='icon'><img src={`skill-ease.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>Ease</div>
                                <div className='progress-number'>3.7</div>
                            </div>

                            <div className='stage-head waiting-head'>
                                <div className='icon'><img src={`skill-purpose.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>Purpose</div>
                                <div className='progress-number'><span className="outof-phrase">&mdash;</span></div>
                            </div>

                            <div className='stage-head waiting-head'>
                                <div className='icon'><img src={`skill-openness.png`} className='plant-img icon-img' /></div>
                                <div className='stage-title'>Openness</div>
                                <div className='progress-number'><span className="outof-phrase">&mdash;</span></div>
                            </div> */}

                            {/* <div className='stage-head effort-head'>
                                <div className='stage-title'>Intensity</div>
                                <div className='progress-number'>42 <span className="outof-phrase">/ 100</span></div>
                            </div>
                            <div className='stage-progress effort-progress'>
                                <div className='stage-progressbar effort-progressbar'>
                                    <div className='stage-fillbar effort-fillbar'></div>
                                </div>
                            </div> */}
                        </div>

                        {/* <SkillList skills={user.skills} increaseName={skillIncreaseName} increaseAmount={skillIncreaseAmount}></SkillList> */}

                        <div className='BioList'>
                            <div className='sidebar-heading'>Your life</div>
                            <div className='BioItem WorkItem' onClick={() => onAboutClick("Work")}>
                                <div className='label'>Work</div>
                                <div className='content'>
                                    {user.about_work_answer && user.about_work_answer.length > 1 ?
                                        user.about_work_answer : 'Write something'}...
                                </div>
                            </div>
                            <div className='BioItem PersonalItem' onClick={() => onAboutClick("Personal")}>
                                <div className='label'>Personal</div>
                                <div className='content'>
                                    {user.about_personal_answer && user.about_personal_answer.length > 1 ?
                                        user.about_personal_answer : 'Write something'}...
                                </div>
                            </div>
                        </div>

                        <div className='CoachSection'>
                            <div className='sidebar-heading'>Your coach</div>
                            <div className='CoachCard'>
                                <img src="coach-brad.png" className='coach-profile-img' />
                                <div className='name-label'>Brad</div>
                            </div>
                            {/* <div className='unlock-instructions'>
                                Complete 3 sessions to unlock
                            </div> */}
                        </div>

                        <div className='AccountSettings'>
                            <div className='account-heading' onClick={handleCreateAccountClick}>
                                <img src="account.png" className='icon-img' />
                                <div className='label'>Account</div>
                            </div>
                            {/* <div className='status-explanation' onClick={handleCreateAccountClick}>
                                Create a free account to save your progress
                            </div> */}
                        </div>
                    </div>

                    {/* <img src="sun.png" className='sun-img' /> */}

                    <div className='page-greeting'>
                        {/* <img src="sun.png" className='sun-img icon-img' /> */}




                        <div className='session-number'>
                            <img src="plant3.png" className='plant-img icon-img' />
                        </div>


                        <div className='label'>
                            {currentDate.toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                            })}
                        </div>

                        <div className='divider'>&nbsp;</div>



                        <div className='session-number session-key'>{user.slam_count}</div>
                        <div className='label label-session'>
                            Your {getOrdinalSuffix(user.slam_count)} session
                        </div>

                        <div className='debugs'>
                            {pageEffectsRef.current.join(" : ")}
                        </div>


                        {/* <div className='divider'>&bull;</div>

                <div className='label'>
                    Your 1st session!
                </div> */}
                    </div>

                    <div className='main-layout'>

                        <div className='custom-col drop-dest'>

                            <div className='heading heading-chat'>
                                <img src="julia-chat.png" className='heading-icon-img chat-icon-img' />
                                <div className='chat-label'>
                                    Your session
                                </div>
                                {viewName === "PostSession" && (
                                    <div className='expanded-chat-label chat-label'>
                                        <img src="great-work-write.png" className='chat-img' />
                                        <div className='chat-label post-session-label'>Great work!</div>
                                    </div>
                                )}
                            </div>

                            {(viewName === "ThoughtPostRating" && isAnimatingCompletedThought) && (
                                <React.Fragment>

                                    <SessionGrid sessionThoughts={sessionThoughts} isAnimatingCompletedThought={true}></SessionGrid>

                                </React.Fragment>
                            )}

                            {(viewName === "NegativeGrid" || viewName === "ThoughtPreRating" || viewName === "PreSession" || viewName === "PostSession") && (
                                <React.Fragment>

                                    <SessionGrid sessionThoughts={sessionThoughts} isPostSession={viewName === "PostSession" ? true : false}></SessionGrid>

                                </React.Fragment>
                            )}

                            {(viewName === "ResponseGrid" || viewName === "ThoughtPostRating") && (
                                <div className={`NegativeThoughtCard-container ${isAnimatingCompletedThought ? 'AnimatedFloat-container' : ''}`}>
                                    <div className={`NegativeThoughtCard Expanded ${isAnimatingCompletedThought ? 'AnimatedFloat' : ''}`}>

                                        <div className='done-button-container'>
                                            <div className={doneButtonClass}>
                                                {/* <img src="checkmark-orange.png" className='checkmark-img' />
                                            <div className='checkmark-label'>Great work!</div> */}
                                                <div className='illustration-container'>
                                                    {/* <img src="notebook.svg" className='notebook-img' /> */}
                                                    <div className="animation-container">
                                                        <img src="julia-writing-alpha.png" className="illustration-img" />
                                                    </div>
                                                </div>
                                                <div className='checkmark-label'>Great work!</div>
                                            </div>
                                        </div>

                                        {thought.type === "Negative" && (
                                            <div className="SessionThought SessionThoughtActive Negative">
                                                <div className='drop-space'>
                                                    <div className='drop-card'>
                                                        <div className='thought-content-active'>
                                                            <div className='thought-label calc-lines-title'>
                                                                {thought.thought}
                                                            </div>
                                                            <div className='thought-label calc-lines-tester' id="fontSizeTester"></div>
                                                            <div className={`strike ${isThoughtSingleLine ? 'strike-line1-single' : 'strike-line1'}`} style={{ width: (strikeWidths[0] * .79) + 'px' }}>&nbsp;</div>
                                                            <div className='strike strike-line2' style={{ width: (strikeWidths[1] * .79) + 'px' }}>&nbsp;</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* {thought.type === "Negative" && (
                                        <div className='NegativeCardContentPlaceholder'>

                                        </div>
                                    )} */}

                                        {thought.type === "Reinforce" && (

                                            <div className='ReinforceCardContentPlaceholder'>

                                            </div>
                                        )}


                                        <div className='response-heading'>Responses</div>

                                        <div className={`response-group Length${responses.length}`} id="responsesDropContainer">
                                            {transitions((styles, item) => item && <animated.div className={`response ${item.isStrongPress ? 'IsStrongPress' : ''}`} style={{ ...styles }}>
                                                <div className='response-content'>

                                                    <div className='response-number'>{item.number}</div>
                                                    <div className='response-icon2'><img src={`skill-${item.area.toLowerCase()}.png`} className={`response-area-img response-area-${item.area.toLowerCase()}`} /></div>

                                                    <div className='response-label'>{item.sentence}</div>
                                                </div>
                                            </animated.div>)}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className='completed-message-container'>
                                {completedSlotMessage !== null && (
                                    <div className={`CompletedMessage Slot${completedSlotMessage.number} ${showCompletedSlotMessage ? 'Show' : 'Hide'}`}>
                                        <div className='content'>
                                            {!completedSlotMessage.video && <div className='icon'><img src="/checkmark-gold2.png" className='icon-img' /></div>}
                                            <div className={`animated-icon animated-icon-${completedSlotMessage.video}`}>
                                                {completedSlotMessage.video === "coffee" && (
                                                    <video width="72" height="72" preload="none" className='completed-video' autoplay="autoplay" loop="true" muted="muted" playsinline="">
                                                        <source src={"coffee.mp4"} type="video/mp4"></source>
                                                    </video>
                                                )}
                                                {completedSlotMessage.video === "pen" && (
                                                    <video width="72" height="72" preload="none" className='completed-video' autoplay="autoplay" loop="true" muted="muted" playsinline="">
                                                        <source src={"pen.mp4"} type="video/mp4"></source>
                                                    </video>
                                                )}
                                                {completedSlotMessage.video === "schedule" && (
                                                    <video width="72" height="72" preload="none" className='completed-video' autoplay="autoplay" loop="true" muted="muted" playsinline="">
                                                        <source src={"schedule4.mp4"} type="video/mp4"></source>
                                                    </video>
                                                )}
                                            </div>
                                            {showCompletedSlotMessage && <div className={`label bounce-label ${completedSlotMessage.video ? 'label-video' : 'label-no-video'}`}>{completedSlotMessage.label}</div>}
                                            {/* <>FF5700 FFB313</> */}
                                        </div>
                                    </div>
                                )}
                            </div>



                        </div>

                        <CSSTransition nodeRef={nodeRef} in={inProp} timeout={2000} classNames="my-node">
                            <div ref={nodeRef} className='main-content-container'>
                                {mainContent}
                            </div>
                        </CSSTransition>


                    </div>
                </WizardContext.Provider>
            </AnimationContext.Provider>
        </div >
    );


}

export default ThoughtGrid2;


function calcLines() {

    const titleEl = document.querySelector('.calc-lines-title');
    const testerEl = document.querySelector('.calc-lines-tester');

    console.log('titleEl', titleEl, testerEl);


    // Build an array of each word used in the original title
    //var allWords = document.getElementById("title").innerText.match(/\S+/g) || [];
    var allWords = titleEl.innerText.match(/\S+/g) || [];

    // The array we will fill with each line
    var lines = [];
    var lineWidths = [];
    // The current line we are working on building
    var currentLine = "";

    var lastAcceptedWidth = -1;

    // Work through the words until we're filling the correct amount of space
    for (var i = 0; i < allWords.length; i++) {
        // Build a new line and check if it is now too large for the container
        var newLine = currentLine + allWords[i] + " ";
        testerEl.innerText = newLine;
        if (
            testerEl.clientWidth >
            titleEl.clientWidth
        ) {
            // If the line is now larger, use the previous line (without the last added word) and reset the current line to just the last word
            lines.push(currentLine.trim());
            //lineWidths.push(lastAcceptedWidth);
            currentLine = allWords[i] + " ";
            lastAcceptedWidth = -2;
        } else {
            // If it's not long enough yet, just keep adding words
            currentLine = newLine;
            lastAcceptedWidth = testerEl.clientWidth;
        }
    }
    // Push any unfinshed final line to the array
    lines.push(currentLine.trim());

    testerEl.innerText = lines[1];

    for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        testerEl.innerText = line;
        lineWidths.push(testerEl.clientWidth * 1.31);
    }

    return lineWidths;

}

function getRotatingCompletionNumber(thoughtCount) {
    return (thoughtCount - 1) % 3 + 1;
}

function getRotatingCompletionVideo(number) {
    switch (number) {
        case 1:
            return "pen";
            break;
        case 2:
            return "coffee";
            break;
        case 3:
            return "schedule";
            break;
        case 4:
            return "pen";
            break;
        case 5:
            return "pen";
            break;
        case 6:
            return "pen";
            break;
        // ... (cases for other days)
        default:
            return "coffee";
    }
}

function getRotatingCompletionLabel(number) {
    switch (number) {
        case 1:
            return "Click, Click!";
            break;
        case 2:
            return "Cheers!";
            break;
        case 3:
            return "Case Closed!";
            break;
        case 4:
            return "Click, Click!";
            break;
        case 5:
            return "Click, Click!";
            break;
        case 6:
            return "Click, Click!";
            break;
        // ... (cases for other days)
        default:
            return "Cheers!";
    }
}

function getRotatingCheckLabel(number) {
    switch (number) {
        case 1:
            return "Keep Going!";
            break;
        case 2:
            return "Almost Done!";
            break;
        case 3:
            return "You're Done!";
            break;
        default:
            return "You're Done!";
    }
}

function getOrdinalSuffix(i) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}