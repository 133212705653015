import React, { useContext, useState, useEffect, useRef } from 'react';
import Back from '../helpers/Back';
import UserContext from '../UserContext';

import './CreateAccountDrawer.scss';

function CreateAccountDrawer({ }) {

    const { user, changeUser } = useContext(UserContext);
    const ref = useRef();

    const [opacity, setOpacity] = useState('0.5');
    const [lockStyle, setLockStyle] = useState(false);

    // Form
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [isFormCompleted, setIsFormCompleted] = useState(false);

    const [formError, setFormError] = useState(null);
    const [showFormError, setShowFormError] = useState(false);

    useEffect(() => {
        controlSubmitState();
        validateForm();
    }, [email, password])


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleSignInSubmitClick = async () => {
    }

    const controlSubmitState = () => {
        setIsSubmitEnabled(
            shouldSubmitBeEnabled()
        );
    }

    const shouldSubmitBeEnabled = () => {
        if (!email || email.length < 4) {
            return false;
        }

        if (!password || password.length < 4) {
            return false;
        }

        return true;
    };

    const validateForm = () => {
        if (!checkEmail(email)) {

            return false;
        }

        if (!checkPassword(password)) {
            return false;
        }

        return true;
    };


    function checkEmail(email) {
        const res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const isValid = res.test(String(email).toLowerCase());

        if (!isValid) {
            setFormError("Please include a valid email address");
        }

        return isValid;
    }

    function checkPassword(password) {
        const isValid = password && password.length > 7;

        if (!isValid) {
            setFormError("Your password should be at least 8 characters in length");
        }

        return isValid;
    }


    function handleCloseClick() {
        setOpacity('0.5');
        setLockStyle(true);

        setTimeout(() => {
            user.CreateAccountDrawer = null;
            changeUser({ ...user });
            setLockStyle(false);
        }, 100);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleCloseClick();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, user, changeUser]);

    let className = 'CreateAccountDrawer drawer';

    if (!user) {
        return (<div></div>);
    }
    if (!user.CreateAccountDrawer) {
        return (<div></div>);
    }

    if (!lockStyle && opacity === "0.5") {
        setTimeout(() => {
            console.log('set opacity 1');
            setOpacity("1");
        }, 50);
    }

    const parentStyles = {};
    //parentStyles.right = '0px';
    parentStyles.opacity = opacity;
    //parentStyles.left = opacity === "0.5" ? "0px" : "0px"

    //console.log('parentStyles', parentStyles);

    //user.completeProjectDrawer = user.projects[0];

    return (
        <div className={className} ref={ref} style={parentStyles}>
            <div className='close-button' onClick={handleCloseClick}>
                <img src="close.svg" className="icon-img" />
            </div>



            <div className="form">
                <div className="form-heading">Create Account</div>
                <div className='form-why'>
                    <img src="create-account.png" className='illustration-img' />
                    <div className='why-p'>
                        A free account saves
                        your progress and lets you
                        do multiple sessions.
                    </div>
                </div>



                <div className='form-subheading'>
                    <span className='step-phase'>Step 1 of 1</span> &bull; <span className='payment-phase'>No payment required</span>
                </div>

                <input type="text" className="input-text" placeholder='Email' onChange={handleEmailChange} style={{ background: 'url(email2.png) #ffffff no-repeat scroll 17px 18px' }} />
                <input type="password" className="input-text" placeholder='Password' onChange={handlePasswordChange} style={{ backgroundSize: '17px 17px', background: 'url(password2.png) #ffffff no-repeat scroll 17px 17px' }} />
                <input type="submit" className="submit-button gtm-trial" value="Create Account" onClick={handleSignInSubmitClick} />
            </div>


        </div>
    )
};

export default CreateAccountDrawer;
