import React, { useContext, useEffect, useState } from 'react';
import './ThoughtPreRating.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';

function ThoughtPreRating({ thought, sessionThoughts, onCompleted }) {

    const { user } = useContext(UserContext);

    const [showSheet, setShowSheet] = useState(false);
    const [showCheck, setShowCheck] = useState(false);

    const [isPopOpen, setIsPopOpen] = useState(user.isNew && user.popRound === 1);

    const handleMeterClick = (rating) => {
        setTimeout(() => {
            onCompleted(rating);
        }, 300);

        setShowCheck(true);

        setTimeout(() => {
            setShowSheet(false);
        }, 200);
    };

    useEffect(() => {
        setTimeout(() => {
            setShowSheet(true);
        }, 10);
    }, []);

    const isReframingType = thought.type === "Reinforce";

    let className = "ThoughtPreRating";

    const currentSessionSlotNumber = sessionThoughts.find(i => i.status === "Active").number;

    className += " SessionSlot" + currentSessionSlotNumber;
    className += showSheet ? " Show" : " Hide";

    return (
        <div className={className}>

            {true && <WizardPop name={'pre-rating'} positions={['bottom']} shift={[-298, 160]} isOpen={isPopOpen} onClose={() => { setIsPopOpen(false) }}></WizardPop>}

            <div className='sheet-heading'>
                How much do you believe this thought?
            </div>

            <div className='sheet Expanded'>
                <div className='sheet-heading-in'>
                    How much do you believe this thought?
                </div>
                <div className='meter-container'>
                    <EmotionMeter onCompleted={handleMeterClick} isShort={false} thought={thought} isFirst={false} fillColor={isReframingType ? 'Orange' : 'Purple'} showCheck={showCheck}></EmotionMeter>
                </div>
            </div>

        </div >
    );


}

export default ThoughtPreRating;
