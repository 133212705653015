import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import AnimationContext from '../AnimationContext';
import Back from '../helpers/Back';
import PageEffect from '../PageEffect';
import UserContext from '../UserContext';
import './SuggestionsSheet.scss';
import ThoughtSetContent from './ThoughtSetContent';
import UI from './UI';


function SuggestionsSheet({ sessionThoughts, options, category, onSelection, onRefreshRequested }) {

    const animations = useContext(AnimationContext);

    const completedThoughtCount = sessionThoughts.filter(
        i => i.status === "Active" || i.status === "Completed"
    ).length;

    const { user, changeUser } = useContext(UserContext);

    const mentalScore = user.context[`mentalScore_${category}`];

    const handleOptionClick = (option, index) => {
        console.log('handleOptionClick', option);

        let callbackDelay = 950;
        if (option.type === "Reinforce") {
            //onSelection(option);

            callbackDelay = 20;

            setTimeout(() => {
                animateReinforceCard(option, index);
            }, 10);
        } else {
            animateNegativeCard(option, index);
        }

        //return;

        setTimeout(() => {
            onSelection(option);
        }, callbackDelay);
    };

    const animateNegativeCard = (option, index) => {
        let optionId = 'suggestions-option-' + category + '-' + index;
        const el = document.getElementById(optionId);

        const elRect = el.getBoundingClientRect();
        const destinationRect = document.getElementById('customThoughtContainer').getBoundingClientRect();

        // setShowFinishingCard(true);
        // setFinishingCardPosition([rect.top + (isReframingType ? 10 : 206), rect.left + 1500]);

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 16.5 + 'px';
        newEl.style.left = elRect.left + 23 + 'px';
        // newEl.style.top = elRect.top + 'px';
        // newEl.style.left = elRect.left + 'px';
        newEl.style.width = "229px";
        newEl.style.width = "249px";
        newEl.style.transform = "scale(1.1)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 1000;
        newEl.classList.add("SelectedClone");
        el.after(newEl);

        // top: 340.18px; left: 314.999px; width: 320px;

        // top: 328.18px;
        // left: 323px;
        // width: 283px;

        el.classList.add("Selected");

        setTimeout(() => {
            newEl.style.transform = "scale(1.25)";
        }, 10);

        setTimeout(() => {
            console.log('completedThoughtCount', completedThoughtCount, sessionThoughts);

            newEl.style.top = destinationRect.top + 10 + (completedThoughtCount * 161.5) + 'px';
            newEl.style.left = destinationRect.left + 58.5 + 'px';

            newEl.style.transform = "scale(1.3)";
            newEl.style.width = "284.5px";
            newEl.style.height = "116px";
        }, 300);

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
            newEl.style.borderRadius = "18px";

            newEl.style.borderRadius = "12px";
            newEl.style.boxShadow = "0.3px 0.3px 1px 0px #b49e6b";

        }, 700);
    };
    const animateReinforceCard = (option, index) => {
        animations.addPageEffect(PageEffect.FadeOutSessionGrid);
        animations.addPageEffect(PageEffect.FadeOutNegativeGrid);

        let optionId = 'suggestions-option-' + category + '-' + index;
        const el = document.getElementById(optionId);
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.classList.add("SessionThoughtExpanded");
        newEl.classList.add("ReinforceInProgress");

        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';

        newEl.style.width = "343px";
        newEl.style.height = "125px";

        newEl.style.width = "293px";
        newEl.style.height = "175px";

        //newEl.style.transform = "scale(1.08)";

        //newEl.style.width = "320px";
        //newEl.style.height = "185px";
        newEl.style.transform = "scale(1)";
        newEl.style.top = elRect.top - 20 + 'px';
        newEl.style.left = elRect.left - 38.5 + 'px';
        //newEl.style.transform = "scale(.95)";
        newEl.style.boxShadow = " 1px 2px 3px 1px #e3e6f7";
        newEl.style.transition = ".4s";

        document.querySelector('.ThoughtGrid2').after(newEl);

        el.classList.add("Selected");

        //return;

        setTimeout(() => {
            // newEl.style.width = "343px";
            // newEl.style.height = "147px";
            newEl.style.transform = "scale(1.075)";
        }, 10);

        setTimeout(() => {
            const destEl = document.querySelector('.drop-dest');
            const destinationRect = destEl.getBoundingClientRect();

            // DEBT: Totally stumped on why the card is misaligned on larger screens.
            const monitorAdd = window.innerWidth > 1800 ? -9 : -1;

            newEl.style.width = "343px";
            newEl.style.height = "147px";
            newEl.style.top = destinationRect.top - 33 + 'px';
            newEl.style.left = destinationRect.left + 7 + monitorAdd + 'px';

        }, 400);

        setTimeout(() => {
            newEl.style.borderRadius = "18px";
            newEl.style.transform = "scale(1.0)";
            newEl.style.transform = "scale(1.0)";
            newEl.classList.add("SessionThoughtExpanded");
            newEl.style.boxShadow = "0.5px 0.5px 2px 0px #e1d1af";

            animations.removePageEffect(PageEffect.FadeOutSessionGrid);
            animations.removePageEffect(PageEffect.FadeOutNegativeGrid);
        }, 1000);
    }

    let className = "SuggestionsSheet";

    className += " " + category;

    return (
        <div className={className}>
            <div className='category-content'>

                <div className='category-heading'>
                    {category}
                </div>
                {/* <img src="work-about-head.png" className='category-img category-work-img' /> */}
            </div>
            <div className='clearfix'></div>

            <div className='options'>

                {options.map((option, index) => {
                    console.log('options map', option, index);
                    let className = 'SuggestionOption ';
                    let optionId = 'suggestions-option-' + category + '-' + index;

                    className += option.type;

                    const thoughtChecks = [
                        {
                            number: 1,
                            status: "Completed"
                        },
                        {
                            number: 2,
                            status: "Completed"
                        },
                        {
                            number: 3,
                            status: "Pending"
                        },
                        // {
                        //     number: 4,
                        //     status: "Pending"
                        // }
                    ];

                    if (option.type === "Reinforce" && option.status === "Empty") {
                        className += " IsEmpty";
                        return (
                            <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId}>
                                <div className='empty-label'>
                                    Complete more cards first
                                </div>
                            </div>
                        )
                    }

                    if (option.type === "Reinforce") {
                        const thisSlot = {
                            ...option,
                            thought: option
                        };
                        return (
                            <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId}>
                                <div className='preview-content'>
                                    <div className='label'>
                                        <div className='label-content'>{UI.removeBracketedWord(option.reframing_thought)}</div>
                                    </div>

                                    <div className='thought-tags'>
                                        {/* <div className='thought-tag tag-calm-mind'>Calm Mind</div> */}
                                        <div className='thought-tag tag-self-esteem'>{option.thought_set.primary_skill}</div>

                                    </div>
                                    <div className='thought-checks'>
                                        {option.reps.map(check => {
                                            let thoughtClass = 'thought-check ' + check.status;


                                            return (
                                                <div className={thoughtClass} key={check.number}>
                                                    <div className='checkmark-container'>
                                                        <img src="checkmark-gold.png" className='checkmark-img' />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div className='area-icon'><img src={`skill-${UI.extractWordInBrackets(option.reframing_thought).toLowerCase()}.png`} className={`area-img area-${UI.extractWordInBrackets(option.reframing_thought).toLowerCase()}`} /></div>
                                    </div>

                                </div>
                                <div className='thought-set-content'>
                                    <ThoughtSetContent slot={thisSlot} isFlat={true} isIncomplete={true} isGrid={false}></ThoughtSetContent>
                                </div>
                            </div>
                        )
                    }

                    return (
                        <div className={className} id={optionId} onClick={() => handleOptionClick(option, index)} key={optionId}>
                            <div className='label'>
                                <div className='label-content'>{option.thought} <span style={{ fontSize: "7.5pt", opacity: .1, display: "none" }}>{option.thought.length}</span></div>
                            </div>
                            <div className='thought-checks'>
                                {thoughtChecks.map(check => {
                                    let thoughtClass = 'thought-check';


                                    return (
                                        <div className={thoughtClass} key={check.number}>
                                            <div className='checkmark-container'>
                                                <img src="checkmark-gold.png" className='checkmark-img' />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
                <div className='clearfix'></div>


            </div>

            {options.length > 0 && (

                <div className='toolbar'>

                    <div className='score'>
                        <div className='score-circle'></div>
                        <div className='score-number'>{mentalScore}%</div>
                    </div>
                    <div className='refresh-container2'>
                        <div className='RefreshButton' onClick={() => { onRefreshRequested(category) }}>
                            <div className='refresh-fill'>
                                <img src="refresh.png" className='refresh-icon-img' />
                            </div>
                        </div>
                    </div>

                </div>
            )}

            {options.length < 1 && (
                <div className='loading-placeholders'>
                    {[1, 2, 3, 4].map(
                        p => {
                            return (
                                <div className='loading-placeholder' key={p}>
                                    <ContentLoader viewBox="0 0 400 160"
                                        height={78} width={320}
                                        backgroundColor="#babcf9"
                                        foregroundColor="#575ac2"
                                    >
                                        <rect x="0" y="21" rx="4" ry="4" width="344" height="9" />
                                        <rect x="0" y="45" rx="3" ry="3" width="205" height="9" />
                                    </ContentLoader>
                                </div>
                            )
                        }
                    )}

                </div>
            )}


        </div>
    );


}

export default SuggestionsSheet;