import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './ThoughtSetContent.scss';
import UI from './UI';


function ThoughtSetContent({ slot, isFlat, isIncomplete, isGrid, hideNumber }) {

    let className = "ThoughtSetContent";

    className += " " + slot.thought.category;

    if (isFlat) {
        className += " IsFlat";
    }
    if (isIncomplete) {
        className += " IsIncomplete";
    }
    if (isGrid) {
        className += " IsGrid";
    }
    if (hideNumber) {
        className += " HideNumber";
    }

    let areaTags = [];
    if (slot.areaIncreases) {
        areaTags = slot.areaIncreases.sort((a, b) => b.points - a.points);
    } else if (slot.thought.areaIncreases) {
        areaTags = slot.thought.areaIncreases.sort((a, b) => b.points - a.points);
    }

    console.log('zz thoughtSetContent', slot, areaTags);

    return (
        <div className={className}>
            {/* 
            <div className='card-number-position'>
                <div className='card-number'>
                    <div className='card-content'>
                        <img src={`checkmark-gold2.png`} className='check-img' />
                        <div className='number-label'>{slot.number}</div>
                    </div>
                </div>
            </div>
            <div className='card-icon-position'>
                <div className='card-icon'>
                    <div className='card-content'>
                        <img src={`skill-optimism.png`} className='check-img' />

                    </div>
                </div>
            </div> */}

            <div className='flat-content'>

                <div className='reframed-content'>
                    <div className='label-content'>
                        <div className='thought-label reframed-label calc-lines-title'>
                            {UI.removeBracketedWord(slot.thought.reframing_thought)}
                        </div>
                    </div>
                </div>

                <div className='original-container'>
                    <div className='original-content'>
                        <div className='label-content'>
                            <div className='thought-label calc-lines-title'>
                                <span className='thought-linethrough'>{slot.thought.thought}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='progress-content'>
                    <div className='checklist thought-checks'>
                        {slot.reps.map(check => {
                            let thoughtClass = 'thought-check ' + check.status;
                            return (
                                <div className={thoughtClass} key={check.number}>
                                    <div className='checkmark-container'>
                                        <img src="checkmark-gold.png" className='checkmark-img' />
                                    </div>
                                </div>
                            )
                        })}
                        {isIncomplete && (<div className='area-icon'><img src={`skill-${UI.extractWordInBrackets(slot.thought.reframing_thought).toLowerCase()}.png`} className={`area-img area-${UI.extractWordInBrackets(slot.thought.reframing_thought).toLowerCase()}`} /></div>)}
                    </div>

                    {!isIncomplete && (
                        <div className='area-tags'>
                            {areaTags.map((area, index) => {
                                if (area.points < 2) {
                                    //return;
                                }
                                if (index > 1) {
                                    return;
                                }

                                let plusSigns = "";
                                if (area.points >= 3) {
                                    plusSigns = "+++";
                                }
                                if (area.points === 2) {
                                    plusSigns = "++";
                                }
                                plusSigns = "";

                                let iconSize = "Small";
                                if (index === 0 || index === 1) {
                                    iconSize = "Big";
                                }

                                let areaClassName = "AreaTag AreaTag" + area.name + " " + iconSize;
                                return (
                                    <div className={areaClassName}>
                                        <div className='area-icon'><img src={`skill-${area.name.toLowerCase()}.png`} className={`area-img area-${area.name.toLowerCase()}`} /></div>
                                        <div className='area-points'><span className='plus-sign'>+</span>{""}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )}


                    {/* <div className='thought-tags'>
                        <div className='thought-tag tag-self-esteem'>{slot.thought.primary_skill}</div>
                    </div> */}
                </div>
            </div>
        </div>
    );


}

export default ThoughtSetContent;