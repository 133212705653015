import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Back from '../helpers/Back';
import './ReframingList.scss';
import ReframingLoadingGrid from './ReframingLoadingGrid';
import UI from './UI';


function ReframingList({ options, isLoading, onOptionSelected }) {

    const [isCompleted, setIsCompleted] = useState(false);
    const [isGrown, setIsGrown] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    let className = "ReframingList";

    if (isLoading) {
        className += ' IsLoading';
    }
    if (isCompleted) {
        className += ' IsCompleted';
    }

    const handleOptionClick = (option) => {
        setIsCompleted(true);
        setSelectedOption(option);

        document.querySelector('.ReframingRefreshToolbar').style.opacity = 0;

        setTimeout(() => {
            setIsGrown(true);
        }, 400);

        setTimeout(() => {
            onOptionSelected(option);
        }, 1000);
    };

    if (isLoading || !Array.isArray(options) || !options.length) {
        return (
            <ReframingLoadingGrid count={4}></ReframingLoadingGrid>
        );
    }

    //const [options, setOptions] = useState([ "My efforts are commendable", "I sometimes take a break", "I have the ability to persevere", "I can ask for help when needed", "I learn from my mistakes", "I am capable of overcoming challenges" ]);

    return (
        <div className={className}>
            {/* <div className='instructions'>
                Choose a new thought that reframes your thought
            </div> */}
            <div className='list'>
                {options.map(
                    (option) => {
                        let className = 'item';

                        const areaName = UI.extractWordInBrackets(option);
                        const sentence = UI.removeBracketedWord(option);

                        let areaClassName = "AreaTag AreaTag" + areaName;

                        if (option === selectedOption) {
                            className += ' IsSelected';

                            if (isGrown) {
                                className += " IsGrown";
                            }

                            return (

                                <div className={className} onClick={() => handleOptionClick(option)}>
                                    <div className='meter-thought-heading meter-thought-heading-new'>
                                        {<img src="checkmark-orange.png" className='checkmark-img' />}
                                        <div className='label'>{sentence}</div>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className={className} onClick={() => handleOptionClick(option)}>
                                {isCompleted && <img src="checkmark-orange.png" className='checkmark-img' />}
                                <div className='label'>{sentence}</div>
                                <div className={areaClassName}>
                                    <div className='area-icon'><img src={`skill-${areaName.toLowerCase()}.png`} className={`area-img area-${areaName.toLowerCase()}`} /></div>
                                    <div className='area-name'>{areaName}</div>
                                </div>
                            </div>
                        )
                    }
                )}
            </div>
            <div className='refresh-position'>
                <div className='refresh-button'></div>
            </div>
        </div>
    );


}

export default ReframingList;