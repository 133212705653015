

export default {
    extractWordInBrackets: (sentence) => {
        const match = sentence.match(/^\[(.*?)\]/);
        return match ? match[1] : "Ease"; // Return the captured word or null if no match
    },

    removeBracketedWord: (sentence) => {
        if (!sentence) {
            return sentence;
        }

        const match = sentence.match(/^\[(.*?)\] (.*)/); // Capture the bracketed word and the rest of the sentence
        return match ? match[2] : sentence; // Return the remaining sentence or the original sentence if no match
    }
}

function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
}