import React, { useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../UserContext';
import './ResponseGrid.scss';
import ResponseLoadingGrid from './ResponseLoadingGrid';
import UI from './UI';
import WizardPop from './WizardPop';



function ResponseGrid({ options, isLoading, isFakeLoading, thought, onResponseAdded, onRefreshRequested }) {

    const { user } = useContext(UserContext);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    const fillingOption = useRef();

    useEffect(() => {
        setTimeout(() => {
            setIsPop1Open(user.isNew && user.popRound === 1);
        }, 1700);
    }, [])

    const flingCard = (option, index, isStrongPress) => {
        let optionId = 'response-option-' + index;
        const el = document.getElementById(optionId);

        const elRect = el.getBoundingClientRect();
        const destinationRect = document.getElementById('responsesDropContainer').getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top - 4.5 + 'px';
        newEl.style.left = elRect.left + 13.5 + 'px';
        newEl.style.width = "280px";
        newEl.style.transform = "scale(1.1)";
        newEl.style.boxShadow = "1px 2px 3px 1px #e3e6f7";
        newEl.style.zIndex = 100 + "";
        document.getElementById('cardClonesContainer').appendChild(newEl);

        el.classList.add("Selected");
        newEl.style.zIndex = 5000;

        if (isStrongPress) {
            el.classList.add("IsStrongPress");
        }

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
        }, 10);

        setTimeout(() => {
            newEl.style.top = destinationRect.top - 40 + 'px';
            newEl.style.left = destinationRect.left + 20 + 'px';
            newEl.style.transform = "scale(0.9)";
            newEl.style.boxShadow = "none";
        }, 130);

        setTimeout(() => {
            newEl.style.boxShadow = "none";
            newEl.style.transform = "scale(0.75)";
        }, 400);

        setTimeout(() => {
            newEl.style.top = destinationRect.top - 0 + 'px';
            newEl.style.opacity = "0";
        }, 600);
    };

    const handleOptionClick = (option, index, isStrongPress) => {

        if (selectedOptions.indexOf(option) !== -1) {
            return;
        }

        setSelectedOptions(prev => {
            return [...prev, option];
        });

        flingCard(option, index, isStrongPress);

        setTimeout(() => {
            onResponseAdded(option, isStrongPress);
        }, 600);
    };

    const handleOptionPress = (option, index) => {
        return;
        fillingOption.current = index;
        setTimeout(() => {
            if (fillingOption.current) {
                return;
            }
            let optionId = 'response-option-' + fillingOption.current;
            let cardEl = document.getElementById(optionId);
            let fillEl = cardEl.querySelector('.filler-fill');
            cardEl.classList.add('Filling');
        }, 50);

        setTimeout(() => {
            if (!fillingOption.current) {
                return;
            }
            let optionId = 'response-option-' + fillingOption.current;
            let cardEl = document.getElementById(optionId);
            let fillEl = cardEl.querySelector('.filler-fill');
            cardEl.classList.remove('Filling');

            handleOptionClick(option, index, true);
        }, 700);
    };

    const handleOptionOff = (option, index) => {
        return;
        let optionId = 'response-option-' + fillingOption.current;
        let cardEl = document.getElementById(optionId);
        cardEl.classList.remove('Filling');
        fillingOption.current = null;
    };

    let className = "ResponseGrid";

    if (isLoading || isFakeLoading) {
        return (
            <div className={className}>

                <div className='sheet-heading'>
                    <div className='category-heading'>Responses</div>
                </div>

                <div className='grid-layout'>
                    <ResponseLoadingGrid count={8}></ResponseLoadingGrid>
                </div>
            </div>
        );
    }

    return (
        <div className={className}>

            {isPop1Open && <WizardPop name={'respond-1'} positions={['left']} shift={[-12, 192]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => setIsPop2Open(true), 100) }}></WizardPop>}
            {isPop2Open && <WizardPop name={'respond-2'} positions={['left']} shift={[564, 288]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false) }}></WizardPop>}

            <div className='sheet-heading'>
                <div className='category-heading'>Responses</div>
            </div>

            <div className='grid-layout'>
                <div className='sheet'>
                    <div className='options'>

                        {options.map((option, index) => {
                            let className = 'option ';
                            const cardId = 'response-option-' + index;

                            let areaName = UI.extractWordInBrackets(option);
                            const splicedLabel = UI.removeBracketedWord(option);

                            const areaOptions = ["Mood", "Motivation", "Focus", "Ease", "Purpose", "Openness"];
                            if (areaName === "Possibility") {
                                areaName = "Openness";
                            }
                            if (!areaOptions.includes(areaName)) {
                                areaName = "Ease";
                            }

                            return (
                                <div className={className} id={cardId} onMouseUp={() => handleOptionOff(option, index)} onMouseDown={() => handleOptionPress(option, index)} onClick={() => handleOptionClick(option, index)} key={cardId}>

                                    <div className='option-positioning'>
                                        <div className='filler'>
                                            <div className='filler-flex'>
                                                <div className='filler-fill'></div>
                                            </div>
                                        </div>
                                        <div className='label'>
                                            {splicedLabel}
                                        </div>
                                        {/* <img src={`skill-${areaName.toLowerCase()}.png`} className='plant-img icon-img' /> */}
                                        <div className='label-good-find'>
                                            Good Find!
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                        <div className='clearfix'></div>

                        <div id="cardClonesContainer"></div>

                    </div>
                </div>
                <div className='controls'>
                    <div className='RefreshButton' onClick={onRefreshRequested}>
                        <div className='refresh-fill'>
                            <img src="refresh.png" className='refresh-icon-img' />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ResponseGrid;