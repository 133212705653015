import React, { useContext, useEffect, useState } from 'react';
import './NegativeGrid.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';

function NegativeGrid({ onThoughtSelected, sessionThoughts }) {

    const { user } = useContext(UserContext);

    const [options, setOptions] = useState([]);
    const [optionsGroup1, setOptionsGroup1] = useState([]);
    const [optionsGroup2, setOptionsGroup2] = useState([]);
    const [optionsGroup3, setOptionsGroup3] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [reframingOptions, setReframingOptions] = useState([]);

    const [isPop1Open, setIsPop1Open] = useState(false); // Set in useEffect after intro ends
    const [isPop2Open, setIsPop2Open] = useState(false);
    const [isPop3Open, setIsPop3Open] = useState(false);

    const [isPop4Open, setIsPop4Open] = useState(user.popRound === 2);
    const [isPop5Open, setIsPop5Open] = useState(user.popRound === 3);

    const handleThoughtClick = async (thought) => {
        onThoughtSelected(thought);
    };

    useEffect(() => {
        loadUser();

        setTimeout(() => {
            if (user.popRound === 1) {
                setIsPop1Open(true);
            }
        }, 11500 + 1250);
    }, []);

    // useEffect(() => {
    //     if (user.popRound === 2) {
    //         setIsPop2Open(true);
    //     }
    //     if (user.popRound === 3) {
    //         setIsPop3Open(true);
    //     }
    // }, [user]);

    const loadUser = async () => {
        setIsLoading(true);

        setTimeout(
            async () => {
                const response = await Back.getNegativeThoughts("Work");

                setOptionsGroup1(response.items);
                setIsLoading(false);
            }, 0
        );

        setTimeout(
            async () => {
                const response = await Back.getNegativeThoughts("Personal");

                setOptionsGroup2(response.items);
                setIsLoading(false);
            }, 0
        );

        setTimeout(
            async () => {
                const response = await Back.getReinforcingThoughts();

                console.log('reinforcing items', response);

                setOptionsGroup3(response.items);
            }, 0
        );
    };

    const handleRefresh = async (category) => {

        console.log('handleRefresh', category);

        setIsLoading(true);

        if (category === "Work") {
            const previousResponses = optionsGroup1;
            setOptionsGroup1([]);
            const response = await Back.getNegativeThoughts("Work", previousResponses);
            setOptionsGroup1(response.items);
        }
        if (category === "Personal") {
            const previousResponses = optionsGroup2;
            setOptionsGroup2([]);
            const response = await Back.getNegativeThoughts("Personal", previousResponses);
            setOptionsGroup2(response.items);
        }
        if (category === "Reinforce") {
            const previousResponses = optionsGroup3;
            setOptionsGroup3([]);
            const response = await Back.getReinforcingThoughts();
            console.log('options3', response);
            setOptionsGroup3(response.items);
        }

        setIsLoading(false);
    };

    let className = "NegativeGrid";

    return (
        <div className={className}>

            {isPop1Open && <WizardPop name={'welcome-to-session'} positions={['right']} shift={[-840, 140]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false); setTimeout(() => setIsPop2Open(true), 100) }}></WizardPop>}
            {isPop2Open && <WizardPop name={'welcome-to-session-2'} positions={['left']} shift={[-0, 160]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false); setTimeout(() => setIsPop3Open(true), 100) }}></WizardPop>}
            {isPop3Open && <WizardPop name={'welcome-to-session-3'} positions={['left']} shift={[-0, 160]} isOpen={isPop3Open} onClose={() => { setIsPop3Open(false); }}></WizardPop>}
            {isPop4Open && <WizardPop name={'back-to-start-1'} positions={['right']} shift={[-840, 200]} isOpen={isPop4Open} onClose={() => { setIsPop4Open(false); }}></WizardPop>}
            {/* {isPop5Open && <WizardPop name={'back-to-start-2'} positions={['left']} shift={[502, 160]} isOpen={isPop5Open} onClose={() => { setIsPop5Open(false); }}></WizardPop>} */}

            <div className='suggestions-content'>

                <div className='group-col'>
                    <SuggestionsSheet options={optionsGroup1} category={"Work"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh}></SuggestionsSheet>
                </div>

                <div className='group-col'>
                    <SuggestionsSheet options={optionsGroup2} category={"Personal"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh}></SuggestionsSheet>
                </div>

                <div className='group-col reframing-group-col'>
                    <SuggestionsSheet options={optionsGroup3} category={"Reinforce"} sessionThoughts={sessionThoughts} onSelection={handleThoughtClick} onRefreshRequested={handleRefresh}></SuggestionsSheet>
                </div>

            </div>

            <div className='reframing-content'>
                {/* <div className='heading'>Or, reinforce a past reframing</div> */}



                <div className='reframing-sheet'>

                    <div className='category-heading category-heading-reframed'>Reframed</div>
                    <div className='clearfix'></div>

                    <div className="options">
                        {reframingOptions.map(option => {
                            let className = 'option ';

                            className += option.type;

                            return (
                                <div className={className} onClick={() => handleThoughtClick(option)}>

                                    <div className='label'>{option.thought}</div>
                                </div>
                            )
                        })}
                        <div className='clearfix'></div>
                    </div>
                </div>
            </div>

            <div className='suggestions-sheet'>



                <div className="options">

                    <div className='category-headings'>
                        <div className='category-col'>
                            <div className='category-heading'>Work</div>
                        </div>
                        <div className='category-col'>
                            <div className='category-heading category-heading-personal'>Personal</div>
                        </div>
                    </div>

                    {options.map(option => {
                        let className = 'option ';

                        className += option.type;

                        return (
                            <div className={className} onClick={() => handleThoughtClick(option)}>
                                <div className='icon-content'></div>
                                <div className='label-content'>{option.thought}</div>
                            </div>
                        )
                    })}
                    <div className='clearfix'></div>

                    {isLoading && (
                        <div className='loading-grid'>
                            <div className='loading-placeholders'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                                    p => {
                                        return (
                                            <div className='loading-placeholder' key={p}>
                                                <ContentLoader viewBox="0 0 400 160"
                                                    height={120} width={280}
                                                    backgroundColor="#babcf9"
                                                    foregroundColor="#575ac2"
                                                >
                                                    <rect x="110" y="21" rx="4" ry="4" width="254" height="7" />
                                                    <rect x="145" y="41" rx="3" ry="3" width="185" height="7" />
                                                </ContentLoader>
                                            </div>
                                        )
                                    }
                                )}

                            </div>
                        </div>
                    )}
                </div>


            </div>


        </div >
    );


}

export default NegativeGrid;
