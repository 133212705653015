import React, { useContext, useEffect, useState } from 'react';
import './ThoughtPostRating.scss';

import GridLoader from "react-spinners/GridLoader";
import ContentLoader from 'react-content-loader';
import SuggestionsSheet from './SuggestionsSheet';
import AiMessage from './AiMessage';
import Back from '../helpers/Back';
import EmotionMeter from './EmotionMeter';
import ReframingList from './ReframingList';
import ThoughtSetContent from './ThoughtSetContent';
import AnimationContext from '../AnimationContext';
import WizardPop from './WizardPop';
import UserContext from '../UserContext';
import UI from './UI';

function ThoughtPostRating({ thought, slam, onCompleted, sessionThoughts, preRating, areaIncreases, onAnimatingCompletedThought }) {

    const { user } = useContext(UserContext);
    const [sessionThought, setSessionThought] = useState(null);

    const [reframingOptions, setReframingOptions] = useState([]);
    const [isReframingLoading, setIsReframingLoading] = useState(true);

    const [reframingThought, setReframingThought] = useState(null);

    const [newRating, setNewRating] = useState(null);
    const [reframingRating, setReframingRating] = useState(null);

    const [showMeters, setShowMeters] = useState(false);
    const [showPickingContent, setShowPickingContent] = useState(true);

    const [sheetContent, setSheetContent] = useState("Meters");

    const [isPop1Open, setIsPop1Open] = useState(false);
    const [isPop2Open, setIsPop2Open] = useState(false);

    const [hideContent, setHideContent] = useState(true);
    const [improvementNumber, setImprovementNumber] = useState(null);

    const animations = useContext(AnimationContext);

    useEffect(() => {

        if (thought.type === "Negative") {
            setTimeout(() => {
                loadReframingOptions();
            }, 100);
        } else {
            setReframingThought(thought.reframing_thought);
            setShowMeters(true);
            setShowPickingContent(false);
        }

        if (thought.type === "Negative") {
            setTimeout(() => {
                setHideContent(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setHideContent(false);
            }, 100);
        }

        if (thought.type === "Negative") {
            if (user.isNew && user.popRound === 1) {
                setTimeout(() => {
                    setIsPop1Open(true);
                }, 2000);
            }
        }

        // setTimeout(() => {
        //     onAnimatingCompletedThought();
        // }, 1000);


    }, []);

    const onReframingOptionSelected = (option) => {
        setReframingThought(option);
        setShowMeters(true);
        setShowPickingContent(false);

        setTimeout(() => {
            setIsPop2Open(user.isNew);
        }, 1000);
    };

    const handleReframingRefreshClick = async () => {
        setIsReframingLoading(true);
        await loadReframingOptions();
        setIsReframingLoading(false);
    };

    const loadReframingOptions = async () => {

        var attempts = 0;
        while (true) {
            attempts = attempts + 1;

            if (attempts > 5) {
                setReframingOptions([]);
                break;
            }

            const response = await Back.getReframingOptions(thought.thought);

            if (!response) {
                continue;
            }

            const items = response.items;

            if (items && Array.isArray(items) && items.length > 0) {

                for (var i in items) {
                    if (typeof items[i] !== "string") {

                        continue;
                    }
                }

                setReframingOptions(items);
                setIsReframingLoading(false);
                break;
            } else {
            }
        }
    };

    const completeThought = async (gauge) => {

        const isReinforce = thought.type === "Reinforce";

        if (!isReinforce) {
            fadeSheetContent();
        } else {
            shrinkSheet();
            setTimeout(() => {
                flingSheet();
            }, 600);
        }

        const newThought = { ...thought };
        newThought.slam_id = slam.id;
        newThought.pre_rating = preRating;
        newThought.rating = gauge;
        newThought.reframing_thought = reframingThought;
        newThought.reframing_rating = reframingRating;

        if (newThought.type === "Negative") {
            newThought.type = "Challenge";
        }

        const reframingArea = UI.extractWordInBrackets(reframingThought);
        newThought.areaIncreases = { ...areaIncreases };
        newThought.areaIncreases[reframingArea] += 3;
        const newAreaIncreases = [
            { name: "Mood", points: newThought.areaIncreases["Mood"] },
            { name: "Motivation", points: newThought.areaIncreases["Motivation"] },
            { name: "Focus", points: newThought.areaIncreases["Focus"] },
            { name: "Ease", points: newThought.areaIncreases["Ease"] },
            { name: "Purpose", points: newThought.areaIncreases["Purpose"] },
            { name: "Openness", points: newThought.areaIncreases["Openness"] },
        ];
        newThought.areaIncreases = newAreaIncreases;

        console.log('zz completeThought', newThought, reframingArea);

        const response = await Back.recordCompletedThought(newThought);

        newThought.context = response.completed_thought.context;
        newThought.primary_skill = response.completed_thought.primary_skill;

        const currentSessionSlotNumber = sessionThoughts.find(i => i.status === "Active") ? sessionThoughts.find(i => i.status === "Active").number : 1;

        const completedSlot = {
            id: currentSessionSlotNumber, number: currentSessionSlotNumber, status: "Completed", thought: { ...newThought }, primary_skill: newThought.primary_skill
        };

        if (newThought.type === "Reinforce") {
            completedSlot.slide_in = true;
            completedSlot.slide_in_number = true;
        } else {
            completedSlot.slide_in_number = true;
        }

        completedSlot.is_hidden = false;
        completedSlot.hide_check = true;

        completedSlot.reps = response.thought_set.reps;
        completedSlot.areaIncreases = newAreaIncreases;

        setSessionThought(completedSlot);

        let completeDelay = 2100;
        if (!isReinforce) {
            setTimeout(() => {
                animateSheetContent();

                animations.addPageEffect("FadeOutSessionGrid");
            }, 50);

            setTimeout(() => {
                animations.removePageEffect("FadeOutSessionGrid");
            }, 550);

            setTimeout(() => {
                animateCardToLeft();
            }, 650 + 250);
        } else {
            animations.addPageEffect("HoldNegativeGrid")
            setTimeout(() => {
                switchReinforceCardContent();
                slideAwayReinforcementCard();
                fadeOutView();
                fadeInSessionGrid(completedSlot);
                //slideInCompletedCard();
            }, 700);

            completeDelay = 1100;

            setTimeout(() => {
                animations.removePageEffect("FadeOutSessionGrid");
            }, completeDelay + 200);
            setTimeout(() => {
                animations.removePageEffect("FadeOutEntireView");
            }, completeDelay + 200);
        }

        setTimeout(() => {
            onCompleted(completedSlot, response.skill_increases, response.user);
        }, completeDelay);
    };

    const handleMeterClick = async (gauge) => {
        setNewRating(gauge);

        const improvement = preRating - gauge;
        setImprovementNumber(improvement);

        let completedDelay = 300;

        if (improvement > 5) {
            document.querySelector('.ImprovementMessage').classList.remove('Hidden');
            setTimeout(() => {
                document.querySelector('.ImprovementMessage').classList.add('Bounce');
            }, 400);

            completedDelay += 900;
        }

        if (reframingRating) {
            setTimeout(() => {
                completeThought(gauge);
            }, completedDelay);
        }
    };

    const fadeSheetContent = () => {
        document.querySelector('.reframe-meter-container').classList.add('Fade');

        setTimeout(() => {
            document.querySelector('.reframe-sheet').classList.add('SessionCardSize');
        }, 350);
    };

    const animateSheetContent = () => {

        setTimeout(() => {
            setSheetContent("SessionCard");
        }, 10);

        setTimeout(() => {
            document.querySelector('.ThisSessionThought').classList.add('Show');
        }, 300);

        setTimeout(() => {
            onAnimatingCompletedThought();
        }, 100);
    };

    const shrinkSheet = () => {
        document.querySelector('.reframe-sheet').classList.add('ReinforceCardShrink');
    };

    const flingSheet = () => {
        document.querySelector('.reframe-sheet').classList.add('ReinforceCardFling');

        setTimeout(() => {
            document.querySelector('.reframe-sheet').classList.add('ReinforceCardFade');
        }, 450);
    };

    const switchReinforceCardContent = () => {
        const el = document.querySelector('.ThoughtSetContent.IsIncomplete .thought-check.Pending')
        el.classList.remove('Pending');
        el.classList.add('Completed');
    };

    const slideAwayReinforcementCard = () => {
        const el = document.querySelector('.ReinforceInProgress');
        el.classList.add('SlideAway');
        el.style.top = "150px";

        setTimeout(() => {
            el.remove();
        }, 1000);
    };

    const fadeOutView = () => {
        animations.addPageEffect("FadeOutEntireView");
    };

    const fadeInSessionGrid = (completedSlot) => {
        onAnimatingCompletedThought(completedSlot);
    };

    const slideInCompletedCard = () => {

    };

    const handleReframingMeterClick = async (gauge) => {
        setReframingRating(gauge);

        if (isReframingType) {
            setNewRating(gauge);
        }

        if (isReframingType || newRating) {

            setTimeout(() => {
                //completeThought();
            }, 2000);

        }
    };

    const animateCardToLeft = () => {
        const el = document.querySelector('.ThisSessionThought');
        const destEl = document.querySelector('.drop-dest');

        const elRect = el.getBoundingClientRect();
        const destinationRect = destEl.getBoundingClientRect();

        const newEl = el.cloneNode(true);
        newEl.id = newEl.id + '-clone';
        newEl.style.position = "fixed";
        newEl.style.top = elRect.top + 12 + 'px';
        newEl.style.left = elRect.left + 18.5 + 'px';
        el.after(newEl);

        el.remove();

        const activeSlot = sessionThoughts.find(i => i.status === "Active");
        const currentSessionSlotNumber = activeSlot ? activeSlot.number : 1;

        let slotTop = 0;
        if (currentSessionSlotNumber === 1) {
            slotTop += -1.5;
        }
        if (currentSessionSlotNumber === 2) {
            slotTop += 161; // was 160.5
        }
        if (currentSessionSlotNumber === 3) {
            slotTop += 322;
        }

        //return;

        setTimeout(() => {
            newEl.style.transform = "scale(1.2)";
            newEl.style.zIndex = "2000"; // So it flys over exiting SessionGrid cards temporarily.
        }, 20);

        setTimeout(() => {
            newEl.style.top = destinationRect.top + 62 + slotTop + 'px';
            newEl.style.left = destinationRect.left + 20 + 'px';
        }, 350);

        setTimeout(() => {
            newEl.classList.add("ThisSessionThoughtFlat");
            newEl.style.transform = null;
            newEl.style.zIndex = null;
        }, 800);
    };

    const isReframingType = thought.type === "Reinforce";

    let className = "ThoughtPostRating";

    if (hideContent) {
        className += " HideContent";
    }

    return (
        <div className={className}>

            {isPop1Open && <WizardPop name={'reframing'} positions={['right']} shift={[-626, 120]} isOpen={isPop1Open} onClose={() => { setIsPop1Open(false) }}></WizardPop>}
            {isPop2Open && <WizardPop name={'post-rating'} positions={['right']} shift={[-330, 160]} isOpen={isPop2Open} onClose={() => { setIsPop2Open(false) }}></WizardPop>}

            <div className={`picking-content ${showPickingContent ? 'Show' : 'Hidden'}`}>
                <div className='sheet-heading sheet-heading-reframing'>
                    <div className='category-heading category-heading-reframe'>Reframe</div>
                    <div className='clearfix'></div>
                </div>

                <div className='sheet Expanded'>
                    <ReframingList options={reframingOptions} onOptionSelected={onReframingOptionSelected} isLoading={isReframingLoading}></ReframingList>
                    <div className='clearfix'></div>
                    <div className='toolbar ReframingRefreshToolbar' style={{ opacity: isReframingLoading ? '0' : '1' }}>
                        <div className='refresh-container2'>
                            <div className='RefreshButton' onClick={handleReframingRefreshClick}>
                                <div className='refresh-fill'>
                                    <img src="refresh.png" className='refresh-icon-img' />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='clearfix'></div>

            <div className={`belief-content ${showMeters ? 'Show' : 'Hidden'}`}>

                <div className='sheet-heading sheet-heading-rerate'>
                    <div className='category-heading'>Reframe</div>
                    <div className='clearfix'></div>
                </div>

                {sheetContent === "Meters" && (
                    <div className='reframe-sheet'>
                        <div className='reframe-meter-container' id='reframeMeterContainer'>


                            <div className='meter-thought-heading meter-thought-heading-new'>
                                {<img src="checkmark-gold2.png" className='checkmark-img' />}
                                <div className='label'>{UI.removeBracketedWord(reframingThought)}</div>
                            </div>
                            <EmotionMeter onCompleted={handleReframingMeterClick} thought={{ thought: reframingThought, type: "Reframing" }} isFirst={false} fillColor={"Orange"} titleIcon={"Check"}></EmotionMeter>

                            <div className='reframe-thought-content'>
                                <div className='meter-thought-heading meter-thought-heading-original'>
                                    <img src="x-icon.png" className='checkmark-img' />
                                    <div className='label'>{thought.thought}</div>

                                </div>
                                <EmotionMeter onCompleted={handleMeterClick} thought={thought} isFirst={false} fillColor={"Purple"} titleIcon={"X"}></EmotionMeter>
                            </div>

                            <div className='ImprovementMessage Hidden'>
                                <div className='improve-bubble'>{improvementNumber}% Improvement</div>
                            </div>
                        </div>
                    </div>
                )}

                {sheetContent === "SessionCard" && sessionThought && (
                    <div className={`SessionThought SessionThoughtFlat SessionThoughtCompleted ThisSessionThought`}>
                        <ThoughtSetContent slot={sessionThought} isGrid={false} hideNumber={true}></ThoughtSetContent>
                    </div>
                )}

            </div>

        </div >
    );


}

export default ThoughtPostRating;